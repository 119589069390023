.staffing-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fd;
    font-family: 'Arial', sans-serif;
  }
  
  .staffing-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .staffing-button-block {
    width: 350px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  

  .staffing-icon {
    height: 80px;
    margin-bottom: 15px;
  }
  
  .staffing-title {
    font-size: 1.2em;
    /* color: #db2777; */
    color: #3e4784;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .staffing-description {
    /* color: #3e4784; */
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .staffing-button {
    /* display: block; */

    display: flex; /* Aligns children (avatar and text) in a row */
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 1em;
    font-weight: 540;
    /* color: #ffffff; */
    color: #db2777;
    /* background-color: #db2777; */
    /* padding: 6px px; */
    border-radius: 6px;
    margin-top: 8px;
    /* transition: background-color 0.3s, transform 0.3s; */
  }
  


  .avatar {
    margin-right: 10px; 
  }
  

  .staffing-login {
    font-weight: 600;
    cursor: default;
  }

  .staffing-login:hover {
    color: #db2777;
    text-decoration: underline;
  }

  .staffing-button-form {
    font-size: 1em!important;
    font-weight: 540!important; 
    color: #db2777!important;
    border-radius: 6px ; 
    background-color: #f8f9fd;
    border: 1px solid #db2777!important;
  }

  .staffing-button-form-add-user {
    font-size: 14px !important;
    font-weight: 540 !important; 
    color: #db2777 !important;
    border-radius: 6px !important; 
    background-color: #f8f9fd !important;
    border: 1px solid #db2777 !important;
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    align-items: center !important;   
    justify-content: center !important; 
    padding: 0 !important; 
    line-height: 1 !important;
}


/* == new styles == */


.staff-allocation-company-create-staffing-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;  /* Align content to the top, not center */
  height: 100vh;
  padding-top: 50px;        /* Add some padding to space it from the top */
  background-color: #f4f5f7;
}

.staff-allocation-company-create-staffing-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: left;         /* Left-align text */
  width: 600px;             /* Increase the width for better spacing */
}

.staff-allocation-company-create-staffing-icon {
  width: 60px;
  margin-bottom: 20px;
}

.staff-allocation-company-create-staffing-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #3c3f42;
  margin-bottom: 10px;
}

.staff-allocation-company-create-staffing-description {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 20px;
}

.staff-allocation-company-create-divider {
  margin-bottom: 20px;
}

.staff-allocation-company-create-staffing-form {
  display: flex;
  flex-direction: column;
  width: 100%;  /* Take up the full width */
}

.staff-allocation-company-create-form-group {
  width: 100%;
  margin-bottom: 20px;
}

.staff-allocation-company-create-form-control {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  font-size: 1rem;  /* Increase font size for better readability */
}

.staff-allocation-company-create-form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;  /* Add space between the two fields */
}

.staff-allocation-company-create-form-row .staff-allocation-company-create-form-group {
  flex: 1;  /* Ensure both fields take up equal space */
}

.btn.staff-allocation-company-create-staffing-btn {
  background-color: #d63384;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
}

.staff-allocation-company-create-custom-support-section {
  margin-top: 20px;
}

.staff-allocation-company-create-autocomplete-container {
  position: relative;
  width: 100%;
}

.staff-allocation-company-create-staffing-cancel-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
