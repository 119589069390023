.account_section{
    width: 100%;
}
.account_section .staffing-new-assignments-body{
    padding: 24px;
}
.account_section .staffing-new-assignments-body .form-control{
    width: 250px;
    font-size: 14px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgb(228, 228, 228);
    background: white;
    height: 35px;
}
.account_section .staffing-new-assignments-body .staffing-set-up-guide-link{
    padding: 8px;
    border-radius: 8px;
    background: #404880;
    color: white !important;
}
.account_section .staffing-new-assignments-body .account_card{
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.09);
    padding: 16px;
    margin-bottom: 16px;
}



/* Container for the calendar icon and text */
.account-integrations-calendar-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 20px;
  }
  
  /* Styling the calendar icon */
  .account-integrations-calendar-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  
  /* Styling for the text section below the icon */
  .account-integrations-calendar-text {
    display: flex;
    flex-direction: column;
  }
  
  /* Calendar label (e.g., Google Calendar) */
  .account-integrations-calendar-label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0px;
  }
  
  /* Email text */
  .account-integrations-email {
    font-size: 13px;
    color: #555;
  }
  
  /* Action links like "Unlink" or "Relink" */
  .account-integrations-action {
    color: #13A391;
    cursor: pointer;
    font-size: 13px;
    text-decoration: underline;
  }
  
  /* Styling for "Link your calendar" */
  .account-integrations-link {
    font-size: 13px;
    color: #13A391;
    cursor: pointer;
    text-decoration: underline;
  }
  