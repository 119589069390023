.team_page{
    width: 100%;
}
.team-staffing-set-up-guide-description {
    background-color: #f0f4ff; /* Light blue background */
    padding: 20px; /* Padding for the main container */
    border-radius: 10px; /* Rounded corners for the main container */
    min-width: 80vw;
}

.team-provider-card {
    padding: 12px;
    display: flex;
    align-items: center;
    width: 100%; /* Ensure the card takes full width */
    border-radius: 8px;
    background: #FFF;
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.05);
}
.team-provider-card:not(:last-child) {
    margin-bottom: 16px;
}
.team-provider-card .team-avatar{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 16px;
}
.team-provider-card .team-avatar img{
    width: 100%;
    height: 100%;
}
.team-provider-card .team-provider-details .team-provider-name{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 2px;
}
.team-provider-card .team-provider-details .team-provider-role{
    color: #878787;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.staffing-set-up-guide-task{
    padding: 24px;
    height: calc(100vh - 197px);
    overflow-y: auto;
}
.team-provider-status{
    display: flex;
    align-items: center;
    gap: 8px;
}
.team-provider-status .team-status .rs-picker-toggle{
    padding: 8px 32px 8px 8px;
}
.team-provider-status .team-status .rs-picker-toggle-value,
.team-provider-status .team-status .rs-picker-toggle-placeholder{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.team-provider-status .team-options{
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    padding: 8px;
}
.team-provider-status .team-options .rs-picker-toggle-indicator{
    top: 10px;
}
.team-avatar {
    margin-right: 10px;
    width: 50px; /* Fixed width for the avatar */
    height: 50px; /* Fixed height for the avatar */
}

.team-provider-role {
    font-size: 12px; /* Smaller font for the role */
    color: #888; /* Gray color for the role */
}

.team-status {
    font-weight: bold; /* Bold font for status */
}

.team-options {
    cursor: pointer; /* Pointer cursor for the options */
}
.create_customer_modal_wrapper .rs-modal-dialog{
    width: 100%;
}
.create_customer_modal_wrapper .rs-modal-header .rs-modal-title{
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 2px;
}
.create_customer_modal_wrapper .rs-modal-header p{
    color: #878787;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
}
.create_customer_modal_wrapper .rs-modal-body{
    margin-top: 0;
}
.create_customer_modal_wrapper .rs-modal-body .email_section{
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    padding: 4px;
    height: 120px;
}
.create_customer_modal_wrapper .rs-modal-body .email_section .email_box{
    color: #A6A6A6;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
}
.create_customer_modal_wrapper .rs-modal-body .email_section .email_box .remove_btn{
    cursor: pointer;
    background: transparent;
}
.create_customer_modal_wrapper .rs-modal-body .email_section input{
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    color: #A6A6A6;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 4px;
}
.create_customer_modal_wrapper .rs-modal-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.create_customer_modal_wrapper .rs-modal-footer::after,
.create_customer_modal_wrapper .rs-modal-footer::before{
    display: none;
}
.create_customer_modal_wrapper .rs-modal-footer .invite_as{
    display: flex;
    align-items: center;
    gap: 8px;
}
.create_customer_modal_wrapper .rs-modal-footer .invite_as p{
    color: #878787;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}
.create_customer_modal_wrapper .rs-modal-footer .invite_as .rs-picker-select .rs-picker-toggle{
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    padding: 8px 32px 8px 10px;
}
.create_customer_modal_wrapper .rs-modal-footer .invite_as .rs-picker-select .rs-picker-toggle .rs-picker-toggle-placeholder,
.create_customer_modal_wrapper .rs-modal-footer .invite_as .rs-picker-select .rs-picker-toggle .rs-picker-toggle-value{
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}
.create_customer_modal_wrapper .rs-modal-footer .invite_btn{
    border-radius: 8px;
    background: #404880;
    padding: 12px 24px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.team-provider-role .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value
{
    color: #555;
    
}