.staffing-set-up-guide-container {
    
    margin: auto; 
    font-family : 'DM Sans', sans-serif !important
  }
  
  .staffing-set-up-guide-title {
    font-size: 14px; 
    font-weight: 600;
    color: #3d4784;
    /* color: #000000; */
  }
  .staffing-set-up-guide-card .customer_lists .customer_name span{
    display: block;
  }
  .staffing-set-up-guide-card .customer_lists .customer_name .address{
    color: #878787;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .staffing-set-up-guide-card .customer_lists .rate_col,
  .staffing-set-up-guide-card .customer_lists .customer_name .rate_col{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .staffing-set-up-guide-card .unassigne_content.customer_lists .badge_tab > span{
    border-radius: 99px;
    background: #F9E9EF;
    padding: 8px 10px;
    color: #CC457D;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }

  .customer_side_drawer{
    background-color: #FAFAFA;
    width: 576px !important;
  }
  .customer_side_drawer .rs-drawer-body{
    padding: 0;
    overflow: unset;
  }
  .customer_side_drawer .rs-drawer-body-close{
    left: -15px;
    top: 68px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.09);
  }
  
  .customer_side_drawer .rs-drawer-content{
    background-color: transparent !important;
  }
  .customer_side_drawer .header_content{
    padding: 24px;
  }
  .customer_side_drawer .header_content .profile{
    width: 96px;
    height: 96px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: lightgray;
    margin: 0 auto 16px;
  }
  .customer_side_drawer .header_content .profile img{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
  .customer_side_drawer .header_content .drawer__title{
    color: #000;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
  }
  .customer_side_drawer .body__content .rs-tabs{
    gap: 0;
    /* height: calc(100vh - 193px); */
    height: calc(100vh - 65px);
  }
  .customer_side_drawer .body__content .rs-nav-subtle{
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .customer_side_drawer .body__content .rs-nav-subtle .rs-nav-item{
    padding: 12px;
    color: #878787;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .customer_side_drawer .body__content .rs-nav-subtle .rs-nav-item.rs-nav-item-active{
    color: #404880;
    font-weight: 600;
  }
  .customer_side_drawer .body__content .rs-nav-subtle .rs-nav-item.rs-nav-item-active::before{
    height: 1px;
    background-color: #404880;
  }
  .customer_side_drawer .body__content .rs-nav-subtle .rs-nav-bar{
    width: calc(100% + 48px);
    left: 0;
    border-color: #E4E4E4;
  }
  .customer_side_drawer .body__content .rs-tabs-content{
    height: calc(100% - 48px);
  }
  .customer_side_drawer .body__content .rs-tabs-content .rs-tab-panel,
  .customer_side_drawer .body__content .rs-tabs-content .tabs__content{
     height: 100%; 
  }
  .customer_side_drawer .body__content .rs-tabs-content .tabs__content{
    padding: 24px;
    overflow-y: auto;
  }
  .customer_side_drawer .body__content .rs-tabs-content .tabs__content .no_data_found{
    margin: auto;
    width: 62%;
    height: calc(100% - 204px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .customer_side_drawer .body__content .rs-tabs-content .tabs__content .no_data_found .magicpen_icon{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #F9E9EF;
    padding: 10px;
    margin: 0 auto 16px;
  }
  .customer_side_drawer .body__content .rs-tabs-content .tabs__content .no_data_found .description{
    color: #616161;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
  }
  .customer_side_drawer .body__content .comment__content .send_comment{
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
    padding: 16px;
  }
  .customer_side_drawer .body__content .comment__content .send_comment textarea{
    width: 100%;
    border: none;
    resize: none;
    box-shadow: none;
    outline: none;
  }
  .customer_side_drawer .body__content .comment__content .send_comment .comment_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .customer_side_drawer .body__content .comment__content .send_comment .comment_btn .btn{
    border-radius: 8px;
    background: #9E9E9E;
    padding: 8px;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  .customer_side_drawer .body__content .comment__content .agreement{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
  }
  .customer_side_drawer .body__content .comment__content .agreement p{
    color: #616161;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
  .customer_side_drawer .body__content .comment__content .agreement .rs-toggle-checked .rs-toggle-presentation{
    background-color: #404880 !important;
  }
  .staffing-set-up-guide-progress {
    background-color: #e0e0e0; /* Light gray as seen in some progress bar backgrounds */
  }
  
  .staffing-set-up-guide-task {
    font-family: inherit; 
  }
  
  .staffing-set-up-guide-link {
    font-size: 14px;
    font-weight: 600;
    /* color: #3d4784 !important; */
    color: #db2777 !important;
    font-family: inherit; 
  }
  
  .checkboxSuccess {
    color: #13A391 ; 
    font-size: 20px!important ;  
    margin-right: 20px ; 
  }

  .checkboxUnfinished {
    color: #c9c4c1 ; 
    font-size: 20px!important ;  
    margin-right: 20px ; 
  }