@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.side_drawer{
    font-family: Inter;
    width: 856px !important;
}
.side_drawer .left_bar{
    width: calc(100% - 296px);
    border-right: 1px solid #E4E4E4;
}
.side_drawer .right_bar{
    width: 296px;
    padding: 24px;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.side_drawer .rs-drawer-content .rs-drawer-body{
    padding: 0;
    overflow: unset;
}
.side_drawer .rs-drawer-content .rs-drawer-body .rs-drawer-body-close{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid #000;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 24px;
    left: -15px;
}
.side_drawer .rs-drawer-content .rs-drawer-body .drawer_content .title{
    padding: 24px 24px 8px;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    line-height: 32px;
    border-bottom: 1px solid #E4E4E4;
}
.side_drawer .rs-drawer-content .rs-drawer-body .drawer_inner_content{
    padding: 24px;
    overflow-y: auto;
    height: calc(100vh - 65px);
}
.side_drawer .rs-drawer-content .rs-drawer-body .drawer_inner_content .sub_titles{
    margin-bottom: 16px;
}
.side_drawer .rs-drawer-content .rs-drawer-body .drawer_inner_content .sub_titles h4{
    color: #616161;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
}
.side_drawer .rs-drawer-content .rs-drawer-body .drawer_inner_content .sub_titles p{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}
.side_drawer .rs-drawer-content .time_amount{
    border-radius: 8px;
    background: #FBF2F6;
    padding: 16px;
    margin-bottom: 16px;
}
.side_drawer .rs-drawer-content .time_amount .amount{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
}
.side_drawer .rs-drawer-content .time_amount .description{
    color: #878787;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}
.side_drawer .rs-drawer-content .shifts_card{
    border-radius: 8px;
    background: #F7F8FA;
    padding: 16px;
    margin-bottom: 16px;
}
.side_drawer .rs-drawer-content .shifts_card .shifts_title{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}
.side_drawer .rs-drawer-content .shifts_card .table thead tr th{
    padding: 0 16px 16px 0;
    border: none;
}
.side_drawer .rs-drawer-content .shifts_card .table thead tr th,
.side_drawer .rs-drawer-content .shifts_card .table tbody tr td{
    color: #000;
    font-size: 12px;
    font-weight: 500;
}
.side_drawer .rs-drawer-content .shifts_card .table tbody tr td{
    color: #878787;
    padding: 16px 16px 16px 0 !important;
    border-top-width: 1px;
    border-bottom-width: 0;
    border-color: #E4E4E4;
}
.side_drawer .rs-drawer-content .offer_card .offer_items .offer_item{
    padding: 8px 0;
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    gap: 8px;
    align-items: center ;
}
.side_drawer .rs-drawer-content .offer_card .offer_items .offer_item:last-child{
    border-bottom: none;
}
.side_drawer .rs-drawer-content .offer_card .offer_items .offer_item .label{
    color: #878787;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    height: auto;
    width: 150px;
}
.side_drawer .rs-drawer-content .offer_card .offer_items .offer_item .details{
    color: #000;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    width: calc(100% - 158px);
}
.side_drawer .rs-drawer-content .offer_card .offer_items .offer_item .details .rs-steps-horizontal .rs-steps-item{
    flex-basis: unset !important;
    display: flex;
    align-items: center;
    gap: 8px;
}
.side_drawer .rs-drawer-content .offer_card .details .rs-steps-horizontal .rs-steps-item .rs-steps-item-content{
    width: auto;
    height: auto;
}
.side_drawer .rs-drawer-content .offer_card .details .rs-steps-horizontal .rs-steps-item .rs-steps-item-content .rs-steps-item-title{
    color: #000;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
}
.side_drawer .rs-drawer-content .offer_card .details .rs-steps-horizontal .rs-steps-item .rs-steps-item-icon-wrapper{
    position: relative;
    width: 20px;
    height: 20px;
    border: none;
    background-color: #B6B9CF;
    color: #404880;
}
.side_drawer .rs-drawer-content .offer_card .details .rs-steps-horizontal .rs-steps-item .rs-steps-item-icon-wrapper .rs-steps-item-icon{
    color: #FFF;
    font-size: 10px;
    font-weight: 500;
}
.side_drawer .rs-drawer-content .offer_card .details .rs-steps-horizontal .rs-steps-item .rs-steps-item-title::after{
    display: none;
}
.side_drawer .rs-drawer-content .offer_card .details .rs-steps-horizontal .rs-steps-item-active .rs-steps-item-icon-wrapper{
    background-color: #404880;
}
.side_drawer .rs-drawer-content .offer_card .details .rs-steps-horizontal .rs-steps-item-active .rs-steps-item-icon{
    color: #FFF;
}
.side_drawer .rs-drawer-content .offer_card .details .rist_step{
    display: flex;
    align-items: center;
    gap:16px;
}
.side_drawer .rs-drawer-content .offer_card .details .rist_step span{
    width: 16px;
    height: 16px;
    background: #CC457D;
    display: inline-block;
    border-radius: 100%;
}
.side_drawer .rs-drawer-content .comment_content{
    margin-bottom: 24px;
}
.side_drawer .rs-drawer-content .comment_content .rs-steps-item-icon-wrapper{
    width: 24px;
    height: 24px;
}
.side_drawer .rs-drawer-content .comment_content .rs-steps-item-icon-wrapper .rs-steps-item-icon{
    font-size: 12px;
}
.side_drawer .rs-drawer-content .comment_content .rs-steps-item-title .comment_title{
    display: flex;
    align-items: center;
    gap: 8px;
}
.side_drawer .rs-drawer-content .comment_content .rs-steps-item-title .comment_title .comment_heading{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    margin: 0;
}
.side_drawer .rs-drawer-content .comment_content .rs-steps-item-title .comment_title .other_details{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 0;
    padding-left: 20px;
}
.side_drawer .rs-drawer-content .comment_content .rs-steps-item-title .comment_title .other_details li{
    color: #878787;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
}

.side_drawer .rs-drawer-content .comment_content .description{
    color: #404040;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
}
.side_drawer .rs-drawer-content .comment_content .document{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 99px;
    border: 1px solid #E4E4E4;
    background: #F7F8FA;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    padding: 6px 9px;
}
.side_drawer .rs-drawer-content .comment_content .rs-steps-item .rs-steps-item-tail{
    left: 11px;
    border-color: #E4E4E4;
}
.side_drawer .rs-drawer-content .send_comment{
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    background: #F5F5F5;
    padding: 16px;
}
.side_drawer .rs-drawer-content .send_comment textarea{
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    color: #9E9E9E;
    font-size: 12px;
    font-weight: 400;
    background: transparent;
}
.side_drawer .rs-drawer-content .send_comment .comment_btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.side_drawer .rs-drawer-content .send_comment .comment_btn .btn{
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    padding: 8px;
    border-radius: 8px;
    background: #9E9E9E;
}
.side_drawer .drawer_content{
    display: flex;
}
.side_drawer .right_bar .title_assi{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 16px;
    border-bottom: 1px solid #E4E4E4;
    line-height: 24px;
    margin-bottom: 16px;
}
.side_drawer .right_bar .data_card {
    margin-bottom: 24px;
}
.side_drawer .right_bar .data_card p{
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
}
.side_drawer .right_bar .data_card h2{
    color: #000;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}
.side_drawer .right_bar .data_card label{
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin-bottom: 8px;
}
.side_drawer .right_bar .data_card .rs-picker-select{
    width: 100%;
}
.side_drawer .right_bar .data_card .other_item{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}
.side_drawer .right_bar .data_card .other_item p{
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    width: 50%;
}
.side_drawer .right_bar .data_card .other_item .badge_status{
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.side_drawer .right_bar .data_card .other_item .high{
    background: #FAE0E0;
    color: #EC605A;
}
.side_drawer .right_bar .data_card .other_item .low{
    background: #CCE1D1;
    color: #82B376;
}
.side_drawer .right_bar .data_card .other_item .medium{
    background: #FBE7D2;
    color: #F2A366;
}
.side_drawer .right_bar .data_card .other_item .right_col{
    width: 50%;
}
.side_drawer .right_bar .data_card .other_item .info{
    color: #000;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    display: flex;
}
.side_drawer .right_bar .data_card .other_item .info span{
    display: block;
    text-align: center;
    width: 100%;
}
.side_drawer .right_bar .send_offer .rs-picker-select{
    width: 100%;
    margin-bottom: 8px;
}
.side_drawer .right_bar .send_offer .rs-picker-select .rs-picker-toggle{
    border-radius: 8px;
    border: 1px solid #D9BA61;
    background: #FFFCF5;
    padding: 12px;
}
.side_drawer .right_bar .send_offer .rs-picker-select .rs-picker-toggle-placeholder,
.side_drawer .right_bar .send_offer .rs-picker-select .rs-picker-toggle .rs-picker-toggle-value{
    color: #D9BA61;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}
.side_drawer .right_bar .send_offer .rs-picker-select .rs-stack-item .rs-picker-clean{
    top: 14px;
}
.side_drawer .right_bar .send_offer .rs-picker-select .rs-stack-item svg{
    top: 10px;
}
.side_drawer .right_bar .send_offer .send_offer_btn{
    border-radius: 8px;
    background: #404880;
    padding: 12px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}
.side_drawer .right_bar .send_offer .send_offer_btn .send_icon{
    display: flex;
    align-items: center;
    gap: 16px;
}
.side_drawer .right_bar .send_offer .send_offer_btn .send_icon .line{
    opacity: 0.16;
    background: #FFF;
    width: 1px;
    height: 16px;
}