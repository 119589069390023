#listing-billboard .listing-section {
    box-sizing: border-box; 
    /* padding: 24px;  */
    height: 100%;
    overflow-y: auto;
}

#listing-billboard .listing-section .title {
    font-size: 20px;
    color: #3e4784;
    font-weight: bold;
    margin-bottom: 0px;
}



#listing-billboard .listing-section .sideBar {
    /* max-width: 257px; */
    width: 100%;
    height: calc(100% - 20px);
    /* margin-bottom: 20px; */
    overflow-y: scroll;
}
.send_offer_page{
    /* height: calc(100vh - 94px); */
    width: 100%;
    overflow: auto; 
    background: linear-gradient(180deg, #D7E1FF 0%, #EEF1FA 80.5%, #FFF 100%);
    box-sizing: border-box; 
    /* padding-bottom: 50px; */
}

#listing-billboard {
    height: calc(100vh - 10px);
    overflow-y: auto;
}

#listing-billboard .listingDetails_section::-webkit-scrollbar {
    width: 5px; 
}

#listing-billboard .listingDetails_section::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

#listing-billboard .listingDetails_section::-webkit-scrollbar-thumb {
    background: #333; /* Handle color */
}

#listing-billboard .listingDetails_section::-webkit-scrollbar-thumb:hover {
    background: #999; /* Handle hover color */
}


#listing-billboard .listing-section .custom-container .custom-row {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    height: 100%;
    flex-wrap: unset;
    gap: 24px;
}

#listing-billboard .listing-section .custom-container .custom-row .listing-content {
    display: flex;
}



#listing-billboard .title .link {
    color: #333333;
    font-size: 12px;
}

#listing-billboard .title {
    font-size: 18px;
    color: #3e4784;
    font-weight: bold;
    margin-bottom: 0px;
}

#listing-billboard .sort_by {
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between; */
    color: #828282;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 24px;
    cursor: default;
}

#listing-billboard .sort_by  .sort_by_item{
    color: #000;
    font-size: 12px;
    font-weight: 400;
}

#listing-billboard .sort_by span {
    /* display: flex; */
    /* align-items: center; */
    /* color: #DF6937; */
    color: #db2777;
    /* margin-left: 5px; */
    /* margin-right: 35%; */
    margin-bottom: 8px;
    cursor: pointer;
}


#price-bidding .rs-input-group {
    width: 68px !important ; 
}


#price-bidding button, input, optgroup, select, textarea{
    color: #3e4784!important ; 
    /* background-color:green !important */
}


@media (max-width: 992px) {
    #listing-billboard .listing-section {
        height: auto;
        min-height: auto;
    }

    #listing-billboard .listing-section .custom-container {
        padding: 20px 15px;
    }

    #listing-billboard .listing-section .custom-container .custom-row {
        flex-wrap: wrap;
        height: auto;
        max-height: unset;
    }

    #listing-billboard .listing-section .custom-container .custom-row .listing-content {
        padding-left: 0;
        width: 100%;
    }

    #listing-billboard .listing-section .sideBar {
        max-width: 100%;
        margin-bottom: 30px;
        width: 100%;
        height: auto;
    }
}

@media (max-width: 575px) {
    #listing-billboard .listing-section .sideBar {
        padding-right: 0;
        max-width: 100%;
        width: 100%;
    }
}
