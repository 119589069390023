.Household-wrapper .Household-block {
    display: flex;
    align-items: center;
}
.Household-wrapper .Household-content{
    width: 56%;
    min-height: 650px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    margin-bottom: 16px;
}
.Household-wrapper .Household-contents{
    padding: 30px;
}
.Household-wrapper .age-contents{
    padding: 24px;
}
.Household-wrapper .age-content{
    width: 43%;
    min-height: 650px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    margin-left: 16px;
    margin-bottom: 16px;
}
.Household-wrapper p {
    margin: 6px 0;
}
.Household-wrapper .Household-title{
    font-size: 16px;
    line-height: 18px;
    position: relative;
}
.Household-wrapper .Household-title::after {
    content: '';
    /* background-image: url('https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/salary_1.svg'); */
    background-image: none !important;
    height: 60px;
    width: 60px;
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.Household-wrapper .Household-title.age::after {
    /* background-image: url('https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/age-group_1.svg'); */
    background-image: none !important;;
}
.Household-wrapper .Household-title.age span {
    color: #F06022;
    font-size: 26px;
}
.age-content .age-description {
    padding-top: 70px;
    /* padding-top: 22px; */
}


.Household-wrapper .Household-description {
    padding-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
@media (max-width:1240px){
    .Household-wrapper .Household-contents,.Household-wrapper .age-contents{
        padding: 20px;
    }
}
@media (max-width:1199px){
    .Household-wrapper .Household-content,.Household-wrapper .age-content{
        min-height: 400px;
    }
    .Household-wrapper .Household-title::after{
        top: 40px;
    }
}
@media (max-width:991px){
    .Household-wrapper .Household-block{
        flex-wrap: wrap;
    }
    .Household-wrapper .Household-content,.Household-wrapper .age-content{
        width: 100%;
    }
    .Household-wrapper .age-content{
        margin-left: 0;
    }
}
@media (max-width:600px){
    .Household-wrapper .Household-description,.age-content .age-description {
        padding-top: 40px;
    }
    .Household-wrapper .Household-title{
        font-size: 15px;
    }
}


@media (max-width:1150px){
    .age-map canvas {
        height: 432px!important;
    }
}
@media (max-width:1100px){
    .age-map canvas {
        height: 410px!important;
    }
}
@media (max-width:1060px){
    .age-map canvas {
        height: 378px!important;
    }
}
@media (max-width:1024px){
    .age-map canvas {
        height: 352px!important;
    }
}
