.Education-wrapper .Education-content{
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    border: 1px solid #E0E0E0;
    margin-bottom: 16px;
}
.Education-title{
    position: relative;
    font-size: 16px;
    line-height: 18px;
}
.Education-wrapper .Education-title::after {
    content: '';
    /* background-image: url(../../images/graduation.png); */
    /* background-image: url(../../../../common/shared-charts/images/graduation.png); */
    height: 60px;
    width: 60px;
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.Education-wrapper .Education-detail{
    padding-top: 50px;
}
@media (max-width:575px) {
    .Education-wrapper .Education-content{
        padding: 15px;
    }
}

