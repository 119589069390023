
a:hover {
  text-decoration: none !important;
}

body {
  font-family: 'DM Sans', sans-serif !important;
}
.connectors-main {
  background-color: #F8FAFC;
  height: 100vh;
  display: flex;
  padding: 50px 0;
  align-items: center;
}
.import-data-block,
.connectors-main{
  background-color: #F8FAFC;
  height: calc(100vh - 89px);
  min-height: 880px;
  padding: 24px;
}
.import-data-block .import-data-block-inner,
.connectors-main .connectors-inner{
  padding: 34px;
  background: #FFFFFF;
  border-radius: 16px;
  height: 100%;
  width: 100%;
  min-height: 800px;
}
.connectors-main .connectors-inner{
  display: flex;
  
  /* align-items: center; */
}
.connectors-content-block{
  margin-bottom: 55px;
}
.connectors-content-inner {
  /* margin-top: 55px; */
  text-align: center;
}

.connectors-content-inner h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  color: #333333;
  /* text-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  margin-bottom: 14px;
}
.connectors-content-inner p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 154%;
  text-align: center;
  margin-bottom: 25px;
  color: #666666;
}
.connectors-three-block {
  text-align: center;
}
.connectors-three-block .row {
  justify-content: center;
  align-items: center;
}
.connectors-item .iconRect {
    /* width: 258px; */
    width: 180px;
    /* height: 150px; */
    border: 1px solid #E0E0E0; 
    background: #FFFFFF;
    border-radius: 4px;
    /* box-shadow: 0px 4px 22px rgb(0 0 0 / 6%); */
    padding: 36px 15px;
}
.connectors-item:nth-child(1) .iconRect img  {
    /* height: 40px;
    width: 40px; */
    height: 48px;
    width: 48px; 
}
.connectors-item:nth-child(2) .iconRect img,
.connectors-item:nth-child(3) .iconRect img ,
.connectors-item:nth-child(4) .iconRect img {
    height: 48px;
    width: 36px;
    /* height: 40px;
    width: 38px; */
}
.connectors-item .iconRect:hover,
.connectors-item .iconRect.iconRectActive {
    border: 1px solid #0071BD;
    /* box-shadow: 0px 4px 22px rgba(203, 91, 117, 0.14); */
    /* box-shadow: 0px 4px 22px #dbe6ec ;  */
}

.connectors-item .iconRect:hover .connectorTxt,
.connectors-item .iconRect.iconRectActive .connectorTxt {
  color: #333333;
}

.connectors-item .connectorTxt {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin-top: 15px;
  color: #828282;
  margin-bottom: 0;
  font-weight: 800;
}
.connectors-three-block{
  margin-bottom: 30px;
}
.connectors-button {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
}
.connectors-button .bckBtn {
  background: rgba(224, 224, 224, 0.4);
  border-radius: 25px;
  font-weight: 700;
  font-size: 13px;
  color: black;
  line-height: 17px;
  padding: 14px 24px;
  cursor: pointer;
}
.connectors-button .bckBtn a{
  color: #0071BD;
}
.connectors-button .nxtBtn {
  border: 1px solid #0071BD;
  border-radius: 25px;
  background: #0071BD;
  font-weight: 700;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 17px;
  padding: 14px 30px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}
.postgreSQL2 .connectors-button .nxtBtn,
.csv-part2 .connectors-button .nxtBtn,
.csv-part1 .connectors-button .nxtBtn {
  flex: 0.3;
}
.connectors-inner .connectors-button .nxtBtn,
.sheets-block .sheets2 .connectors-button .nxtBtn {
  flex: none;
}
.postgreSQL2 .connectors-button,
.csv-part2 .connectors-button,
.connectors-inner .connectors-button {
  justify-content: center;
}
.connectors-bottom-section {
  margin-right: 0;
}
.connectors-button .nxtBtn:hover{
  background: #F8FAFC;
  color: #0071BD;
}
.connectors-button .bckBtn:hover a{
  color: #fff;
}
.connectors-button .bckBtn:hover,
.nxtBtnActive {
  background: #0071BD;
  color: white ; 
}
.import-data-block .rs-steps-horizontal {
  max-width: 475px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 30px;
}
.import-data-block .rs-steps-horizontal:after{
  position: absolute;
  content: '';
  width: 90%;
  height: 2px;
  top: 15px;
  left: 0;
  right: 0;
  background-color: #CFCFCF;
  margin: 0 auto;
}
.import-data-block .rs-steps-horizontal .rs-steps-item {
  min-height: 100%;
  padding: 0;
  overflow: visible;
  display: flex;
  align-items: center;
  vertical-align: middle;
  flex-basis: initial !important;
  flex-grow: inherit;
  position: relative;
  z-index: 9;
  text-align: center;
  flex-shrink: inherit;
  background: white;
  border-width: 0 10px;
  border-style: solid;
  border-color: white;
}
.import-data-block .rs-steps-horizontal .rs-steps-item .rs-steps-item-icon-wrapper{
  position: inherit;
  width: 24px;
  height: 23px;
  text-align: center;
  line-height: 21px;
  border: 1px solid #CED7E1;
  background: #F6F6F6;
  font-style: normal;
  margin: 0 auto;
  margin-right: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #5D5D5D;
  left: 0;
  right: 0;
  border-radius: 4px;
}
.rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper{
  left: 0 !important;
}
.rs-steps-horizontal .rs-steps-item{
  padding-left: 0 !important;
}
.import-data-block .rs-steps-horizontal .rs-steps-item.rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon){
  border: 1px solid #0071BD;
  color: white; 
  background: #0071BD;
}
.import-data-block .rs-steps-horizontal .rs-steps-item.rs-steps-item-status-process .rs-steps-item-title{
  color: #0071BD;
}
.import-data-block .rs-steps-horizontal .rs-steps-item .rs-steps-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #86909A;
  padding: 0;
}
.import-data-block .rs-steps-horizontal .rs-steps-item .rs-steps-item-content {
  height: auto;
  width: auto;
}
.import-data-block .rs-steps-horizontal .rs-steps-item-title::after{
  display: none;
}
.csv-title h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 14px;
}
.csv-title p {
  font-weight: normal;
  font-size: 14px;
  line-height: 154%;
  text-align: center;
  color: #666666;
  margin-bottom: 38px;
}
.csv-title {
  text-align: center;
}
.csv-title .dropzone-wrapper {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 6%);
  border-radius: 4px;
  padding: 30px 30px 50px 30px;
}
.csv-title .dropzone-wrapper .file-list-wrapper .file-list-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 12px;
  text-align: left;
}
.csv-title .file-list-item-wrapper .file-list-item {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 4px;
}
.csv-title .file-list-item ~ .file-list-item {
  margin-top: 10px;
}
.csv-title .file-list-item-wrapper .file-list-item .file-list-item-title {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 154%;
  color: #333333;
  margin-bottom: 6px;
}
.file-list-item-process-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}
.file-list-item-process-title .file-list-item-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 154%;
  color: #666666;
}
.file-list-item-process .progress {
  background-color: #F3F3F3;
  height: 3px;
  border-radius: 100px;
}
.file-list-item-process .progress .progress-bar {
  background-color: #0071BD;
  border-radius: 100px;
}
.csv-block {
  max-width: 521px;
  margin: 0 auto;
}
.import-data-block .csv-block .connectors-bottom-section {
  margin: 0;
}
.csv-block .csv-top-block {
  margin-bottom: 20px;
}
.csv-block .dropzone {
  background: #FFFFFF;
  border: 1px dashed #0071BD;
  box-sizing: border-box;
  box-shadow: 0px 4px 22px #0099ff17;
  border-radius: 16px;
  padding: 32px 25px;
  margin-bottom: 25px;
}
.csv-title .dropzone-wrapper p {
  font-weight: normal;
  font-size: 14px;
  line-height: 154%;
  text-align: center;
  color: #666666;
  margin-bottom: 4px;
}
.csv-title .dropzone-wrapper .dropzone svg {
  margin-bottom: 15px;
}
.import-data-block .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
    width: 24px;
    height: 23px;
    text-align: center;
    line-height: 24px;
    color: #fff;
    background-color: #0071BD;
    border-radius: 4px;
    font-weight: 500;
}
.import-data-block .rs-steps-item.rs-steps-item-status-finish .rs-steps-item-title {
  color: #0071BD;
}
.csv-part2 h2.table-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #333333;
  margin-bottom: 34px;
}
.csv-import-data-inner .rs-table.rs-table-hover {
  width: 100% !important;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 6%);
  border-radius: 4px;
}
.import-data-block .csv-part2 .connectors-bottom-section {
  margin: 0;
  padding-top: 20px;
}
.sheets-block .sheets1 {
  max-width: 455px;
  margin: 0 auto;
  text-align: center;
}
.sheets1-title h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  margin-bottom: 14px;
}
.sheets1-title p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 154%;
  text-align: center;
  color: #666666;
}
.sheets1-title {
  margin-bottom: 34px;
}
.sheets-block .sheets1 .sheets1-icon .sheetsEnable {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 6%);
  border-radius: 4px;
  padding: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sheets-block .sheets1 .sheets1-icon .sheetsEnable img {
  width: 60px;
}
.sheets-block .sheets1 .sheets1-icon .sheetsEnable span {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #333333;
  padding-left: 15px;
}
.sheets-block .connectors-bottom-section {
  margin: 0;
  padding-top: 20px;
}
.sheets2 {
  text-align: center;
  max-width: 686px;
  margin: 0 auto;
}
.sheets2-form {
  text-align: left;
  padding-top: 24px;
  margin-bottom: 50px;
}
.sheets2-form .sheetsEnable .title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 11px;
}
.sheets2-form .sheetsEnable .field-wrapper input {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 6%);
  border-radius: 4px;
  width: 100%;
  padding: 11px 20px;
  outline: none;
}
.sheets2-form .sheetsEnable .field-wrapper {
  margin-bottom: 20px;
}
.sheets2-form .sheetsEnable .sheet-example-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 7px;
}
.sheets2-form .sheetsEnable .example {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #646464;
}
.sheets-block .sheets3 h2.table-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #333333;
  margin-bottom: 34px;
}
.postgreSQL1 {
  max-width: 449px;
  margin: 0 auto;
}
.postgreSQL1-inner .form-wrapper {
  background: #FFFFFF;
  border-radius: 4px;
}
.postgreSQL-title h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
  margin-bottom: 4px;
}
.postgreSQL-title p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #4F4F4F;
  margin: 0;
}
.postgreSQL-title {
  text-align: center;
  margin-bottom: 34px;
}
.postgreSQL .connectors-bottom-section {
  margin: 0;
  padding-top: 20px;
}
.postgreSQL1-inner .form-wrapper .form-item input {
  width: 100%;
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 13px 16px 13px 18px;
  outline: none;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  line-height: 17px;
  font-size: 13px;
}
.postgreSQL1-inner .form-wrapper .title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 8px;
}
.postgreSQL1-inner .form-wrapper .form-item {
  margin-bottom: 14px;
}
.postgreSQL1-inner .form-wrapper .form-item .notice {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  padding-top: 10px;
}
.form-item2 {
  display: flex;
  align-items: center;
}
.form-item2 .form-item.host {
  width: 50%;
  padding-right: 10px;
}
.form-item2 .form-item.Port {
  width: 50%;
}
.postgreSQL-inner-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #333333;
  margin-bottom: 34px;
}
.postgreSQL2-inner .form-wrapper {
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 6%);
  border-radius: 4px;
}
.postgreSQL2 {
  max-width: 748px;
  margin: 0 auto;
}
.All-import-data table thead tr th {
    width: 16.66%;
    padding: 17px 20px;
}
.All-import-data table thead tr {
  width: 100%; 
}
.All-import-data table {
  width: 100%; 
}

.table-responsive {
  max-height: 60vh; 
  overflow-y: scroll; 
}

.All-import-data .select-item-inner {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.All-import-data table thead tr th.import-table-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 17px 20px;
  text-transform: uppercase;
  color: #404040;
}
.All-import-data table tbody tr td {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  width: 16.66%;
  padding: 17px 20px;
}
.All-import-data table tbody tr ~ tr {
  border-top: 1px solid #E4E4E4;
}
.select-item-inner select.custom-select {
  border: 1px solid #E4E4E4;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 6%);
  border-radius: 4px;
  background: #ffffff;
  /* background-image: url(../assest/img/downarrow.png); */
  /* background-image: url(./components/asset/img/downarrow.png); */
  background-repeat: no-repeat;
  background-position: 95% center;
  min-width: 106px;
  padding: 5px 10px;
  height: auto;
  line-height: initial;
  font-size: 14px;
}

.select-item-inner select.custom-select.active {
  border: 1px solid #F20100
}

.select-item-inner .custom-info {
  padding-left: 10px;
}
.All-import-data {
  height: auto;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  box-shadow: 0px 4px 22px rgb(0 0 0 / 6%);
  border-radius: 4px;
}

.paginate{
  margin: 20px 0 !important;
  padding: 0;
  display: flex;
  justify-content: end;
  list-style: none;
  gap: 10px;
}
.paginate li a{
  padding: 10px 15px;
  display: block;
  text-decoration: none;
  color: black;
  border-radius: 5px;
}
.paginate li a:hover{
  background: rgba(224, 224, 224, 0.4);
  color: black;
  border-radius: 5px;
}
.paginate .selected{
  background: #0071BD;
  color: white;
  border-radius: 5px;
}
.paginate .disabled a{
  cursor: no-drop;
}
.paginate .disabled a:hover{
  background: transparent;
  color: black;
}

.pdfPreview .import-data-block-inner{
  height: auto;
}
/* mobile View */
@media(max-width:1199px){
  .container{
    max-width: 100%;
    width: 100%;
  } 
  .connectors-three-block .connectors-item {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }
  .connectors-bottom-section {
    margin-right: 90px;
  }
  .connectors-three-block {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
@media(max-width:991px){
  .connectors-bottom-section {
    margin-right: 0;
  }
  .connectors-item .iconRect {
    width: 100%;
  }
  .connectors-three-block {
    max-width: 100%;
  }
  .connectors-main{
    display: block;
  }

}

@media(max-width:860px){
  .connectors-item .iconRect{
    width: 100%;
  }
}
@media(max-width:575px){
  .classificationTopics .container .classificationTopics-progress{
    flex-wrap: wrap;
    justify-content: center;
  }
  .connectors-three-block .row {
    display: block;
    text-align: center;
  }
  .connectors-three-block .connectors-item {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .connectors-item ~ .connectors-item {
    padding-top: 20px;
  }
  .postgreSQL1 {
    max-width: 100%;
  }
  .connectors-button .nxtBtn,
  .connectors-button .bckBtn {
    padding: 10px 15px;
    font-size: 13px;
  }
  .connectors-button .nxtBtn {
    margin-left: 10px;
  }
  .sheets-block .sheets1 {
    max-width: 100%;
  }
  .sheets-block .sheets1 .sheets1-icon .sheetsEnable {
    padding: 30px 15px; 
  }
  .sheets-block .sheets1 .sheets1-icon .sheetsEnable span {
    font-size: 20px;
  }
  .sheets-block .sheets1 .sheets1-icon .sheetsEnable img {
    width: 40px;
  }
  .sheets2-form {
    padding-top: 0;
    margin-bottom: 20px;
  }
  .csv-title .dropzone-wrapper {
    padding: 15px;
  }
  .csv-block .dropzone {
    padding: 22px 25px;
    margin-bottom: 20px;
  }
  .csv-title .dropzone-wrapper .dropzone svg {
    margin-bottom: 15px;
    width: 40px;
    height: 40px;
  }
  .postgreSQL-inner-title,
  .sheets-block .sheets3 h2.table-title,
  .csv-part2 h2.table-title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .postgreSQL-title h2,
  .sheets1-title h2,
  .csv-title h4 {
    font-size: 20px;
  }
  .postgreSQL-title,
  .sheets1-title,
  .csv-title p{
    margin-bottom: 20px;
  }
  .parsed-content .import-data-block-inner{
    padding: 24px 0;
    height: auto;
  }
  .orderProcessing .import-data-block-inner .container .ordercard_containt{
    padding: 20px 10px !important;
  }
  .import-data-block .import-data-block-inner .Preparation-container{
    padding: 20px 10px !important;
  }
  .orderProcessing .import-data-block-inner .ordercard .cardlabel{
    margin-bottom: 2px !important;
  }
}
/* HOME Page css */
.home-block {
  width: 100%;
  background-color: #F8FAFC;
  height: 100vh;
  
}
.home-block .home-content{
  height: 100%;
  width: 100%;
}
.home-right {
  width: 100%;
  height: calc(100% - 89px);
}
.sidepanel-block {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
  width: 290px;
  height: 100%;
  z-index: 999;
}
.sidepanel-block .sidepanel-inner{
  width: 290px;
  height: 100%;
  overflow-y: auto;
}

.search-panel {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 4%); 
}

.sidepanel-block-notActive {
  background:#272C36;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
  width: 65px;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  height: 100%;
}


.sidepanel-block::-webkit-scrollbar{
  width: 1px;
  background-color: transparent;
}
.sidepanel-item{
  padding: 15px 30px 13px;
  border-bottom: 1px solid #EFEFEF;
}
.sidepanel-logo{
  padding: 23px 20px 23px 30px;
}
.sidepanel-logo svg{
  visibility: hidden;
}
.sidepanel-item:last-child{
  border: none;
}
.sidepanel-profile-inner {
  display: flex;
  align-items: center;
}
.sidepanel-profile-img {
  position: relative;
}
.sidepanel-profile-img img.Profile-cover {
  width: 56px;
  height: 56px;
}
.sidepanel-profile-img img.profile-Verify {
  position: absolute;
  bottom: 0;
  right: 0;
}
.sidepanel-profile-content h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 4px;
}
.sidepanel-profile-content p {
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  margin: 0;
}
.sidepanel-profile-content {
  padding-left: 10px;
}
.sidepanel-account-inner h2,
.sidepanel-subscription-inner h2,
.sidepanel-username-inner h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 7px;
  color: #333333;
}
.sidepanel-account-inner p,
.sidepanel-username-inner p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  margin: 0;
}
.sidepanel-subscription-inner p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  margin-bottom: 5px;
}
.sidepanel-subscription-inner h6 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #0071BD;
  margin: 0;
}
.sidepanel-menu-inner h6 {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6B6B6B;
  margin-bottom: 27px;
}
.sidepanel-download h4 {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 16px;
}
.sidepanel-download img {
  max-width: 143px;
}
.sidepanel-download {
  margin-bottom: 31px;
}
.sidepanel-listing {
  list-style: none;
  padding: 0;
  margin: 0;
} 
.sidepanel-listing li {
  margin-bottom: 26px;
}
.sidepanel-listing li:last-child {
  margin-bottom: 0;
}
.listing-item{
  display: flex;
  align-items: center;
}
.listing-item span {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  padding-left: 10px;
}
.navigation-block {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
  /* padding: 21px 24px; */
  padding: 18px 21px;
  position: sticky;
  top: 0;
}
.navigation-left h2 {
  font-weight: bold;
  font-size: 22px;
  /* font-size: 18px; */
  line-height: 26px;
  color: #333333;
  margin-bottom: 5px;
}
.navigation-left p {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin: 0;
}
.navigation-inner {
  display: flex;
  align-items: center;
  justify-content: space-between; /* comment if sidepanel is to be removed */ 
}
.navigation-inner .logo svg{
  /* display: none; */
  padding: 23px 20px 23px 30px;
}
.navigation-inner .logo img{
  /* display: none; */
  /* padding: 10px; */
}

.parsed-content .navigation-left{
  max-width: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.WorkspaceType .type-card{
  display: flex;
  padding: 24px;
  background: #FFFFFF;
  border: 1.5px solid #E0E0E0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  cursor: default;
  position: relative;
}
.WorkspaceType .active{
  border: 1.5px solid #0071BD;
}
.WorkspaceType .container{
  height: 100%;
  /* display: flex; */
  /* align-items: start; */
  justify-content: center;
}
.WorkspaceType .card-body .card-data{
  margin-right: 30px;
}
.WorkspaceType .type-content{
  max-width: 100%;
  display: grid;
  gap: 24px;
  align-items: self-start;
  grid-template-columns: 50% calc(50% - 24px);
}
/* .WorkspaceType .type-card:not(:last-child){
  margin-bottom: 16px;
} */
.WorkspaceType .type-card .card-img{
  width: auto;
}
.WorkspaceType .type-card .card-body{
  padding: 0 0 30px 0;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.WorkspaceType .type-card .card-body .card-data h2{
  color: #333333;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 4px;
}
.WorkspaceType .type-card .card-body .card-data p{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4F4F4F;
}
.WorkspaceType .bage{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 176px;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0px 8px 0px;
}
.WorkspaceType .bage{
  background: #CB5B75;
}
.WorkspaceType .learning{
  background: #e18457;
}
.WorkspaceType .connectors-button{
  width: 50%;
  margin: auto;
}

.classificationTopics .container{
  max-width: 1440px;
}
.classificationTopics .container .title{
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: #333333;
}
.classificationTopics .container .sub-title{
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4F4F4F;
}
.classificationTopics .container .classificationTopics-progress{
  max-width: 65%;
  /* margin: 0 auto 34px; */
  margin: 0 auto 14px;
}
.classificationTopics .import-data-block{
  height: auto;
}
.classificationTopics-content .progress-container-finetune .rs-progress-line-inner{
  background: #EDEDED;
}
.classificationTopics-content-inner .rs-progress-line-bg, 
.classificationTopics-content-inner .rs-progress-line-inner {
  height: 6px !important;
}
.classificationTopics-content .progress-container-finetune .rs-progress-line-inner .rs-progress-line-bg{
  background: #0071BD !important;
}
.classificationTopics-content .progress-container-finetune .rs-progress-info-status{
  font-weight: 500;
  line-height: 21px;
}
.classificationTopics-progress .rs-btn{
  margin-left: 12px;
  background: #0071BD;
  border-radius: 25px;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-right {
  display: flex;
  align-items: center;
}
.navigation-profiles-inner {
  display: flex;
  align-items: center;
}
.navigation-profiles-inner img {
  width: 40px;
}
.navigation-profiles-inner h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  margin: 0 20px 0 10px;
}
.navigation-notification {
  padding-right: 30px;
  position: relative;
}
.notification-data ul {
  list-style: none;
  padding: 15px;
  margin: 0;
  overflow-y: auto;
  max-height: 310px; 
}

.notification-data-unread {
  background-color: #f0f0f0 !important;
}


.notification-data ul::-webkit-scrollbar{
  width: 2px;
  background-color: #0071BD;
}
.notification-item {
  /*
  display: flex;
  align-items: center;
  */
  display: flex;
  align-items: center;
  padding: 12px;

}
.notification-item .notification-img img {
  width: 40px;
  height: 40px;
}
.notification-content h3 {
  /*
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #333333;
  margin-bottom: 5px;
  */

   font-size: 14px;
  line-height: 16px;
  color: #666666;
  margin-bottom: 0.5rem;
}
.notification-data .notification-button {
  border-top: 1px solid #EFEFEF;
  padding: 15px;
  text-align: center;
}
.notification-data .notification-button button {
  background-color: #0071BD;
  color: #ffffff;
  border: none;
  width: 100%;
  padding: 8px 10px;
  font-size: 16px;
  border-radius: 5px;
}
.notification-data .notification-button button:hover{
  background-color:#000000;
}
.notification-content p {
  /*
  margin: 0;
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  */
  font-weight: 500;
  font-size: 13px;
  color: #828282 ; 
}
.notification-content {
  padding-left: 15px;
}
.notification-data ul li ~ li {
  border-top: 1px solid #EFEFEF;
  padding-top: 15px;
  margin-top: 15px;
}
.notification-title h2 {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  margin: 0;
}
.notification-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #EFEFEF;
  padding: 15px;
}
.navigation-notification .notification-icon svg {
  cursor: pointer;
  /* width: 22px; */
  width: 24px;
  height: auto;
}
.notification-block {
  background-color: #fff;
  box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
  border-radius: 5px;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 400px;
  opacity: 0;
  visibility: hidden;
}
.notification-icon {
  position: relative;
}
.notification-icon .notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
}
.notification-icon .notification-count span {
  /* background-color: #1DA1F2; */
  background-color: #C2262D;
  color: #fff;
  width: 20px;
  height: 20px;
  display: block;
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  line-height: 20px;
}
.navigation-notification .notification-block-open{
    opacity: 1;
    visibility: visible;
    
}
/* .navigation-notification:hover .notification-block{
    opacity: 1;
    visibility: visible;
} */
.notification-title button {
  color: #e51d1d;
  background-color: #e51d1d38;
  border: none;
  border-radius: 100px;
  padding: 3px 15px;
  font-size: 12px;
}
.workspaces-block {
  padding: 24px;
  height: 100%;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}
.workspaces-inner-block{
  width: 100%;
  overflow-y: scroll;
  height: 100%;
}
.home-right .workspaces-block .workspaces-inner-block::-webkit-scrollbar-track
{
	border-radius: 10px;
}

.home-right .workspaces-block .workspaces-inner-block::-webkit-scrollbar
{
	width: 5px;
}

.home-right .workspaces-block .workspaces-inner-block::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.search-workspaces{
  padding: 16px 24px;
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.search-workspaces svg{
  margin-right: 10px;
}
.search-workspaces input{
  color: #4F4F4F;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  border: none;
  outline: none;
}
.workspaces-title-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.workspaces-title h2 {
  /* font-weight: bold; */
  /* font-size: 24px; */
  line-height: 28px;
  color: #333333;
  margin: 0;
}
.workspaces-button button {
  background-color: white;
  /* padding: 11px 20px; */
  padding: 5px 9px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #4F4F4F;
  display: flex;
  align-items: center;
}
.workspaces-button {
  display: flex;
  align-items: center;
}
.workspaces-button button ~ button {
  margin-left: 15px;
}
.workspaces-button button svg {
  margin-right: 5px;
}
.workspaces-item-block ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.workspaces-item-block ul li {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #F7FAFC ; 
}
.workspaces-item-block ul li:not(:last-child) {
  margin-bottom: 15px;
}

.workspaces-item-block ul li:hover {
  border: 1px solid #e6e8ea ; 
}
.workspaces-item-block{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  gap: 24px;
}
.workspaces-item-block .add-workspaces{
  background: rgba(0, 113, 189, 0.06);
  border: 1px solid #0071BD;
  border-radius: 8px;
}
.workspaces-item-block .add-workspaces .workspaces-item{
  
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent !important;
}
.workspaces-item-block .add-workspaces .workspaces-item svg{
  margin-bottom: 12px;
}
.workspaces-item-block .add-workspaces .add-workspaces-text{
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #0071BD;
}
.workspaces-item-block .workspaces-items .workspaces-item{
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.workspaces-items-discover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.discover-image {
  width: 100px; /* Adjust the width to fit your image */
  height: auto; /* Maintain aspect ratio */
  margin-right: 16px; /* Adjust the spacing between image and text */
}

.discover-text {
  font-size: 20px; /* Adjust the font size as desired */
  font-weight: bold; /* Adjust the font weight as desired */
  color: #333333; /* Adjust the text color */
}

/*
.add-workspaces-discover {
  background: rgba(0, 113, 189, 0.06);
  border: 1px solid #0071BD;
  border-radius: 8px; 
}
*/

.workspaces-item-block .workspaces-items .workspaces-item .workspaces-user-name{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.workspaces-item-block .workspaces-items .workspaces-item .workspaces-user-name .avatar{
  display: flex;
  align-items: center;
}
.workspaces-item-block .workspaces-items .workspaces-item .workspaces-user-name .avatar .emoji{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 30px;
}
.workspaces-item-block .workspaces-items .workspaces-item .workspaces-user-name .avatar span{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-left: 8px;
  font-family: 'Poppins';
}
.workspaces-item-block .workspaces-items .workspaces-item .workspaces-user-name .delete{
  width: 36px;
  height: 36px;
  /* background: rgba(235, 87, 87, 0.06); */
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.workspaces-item-block .workspaces-items .workspaces-item .workspaces-user-name .delete svg{
  width: 16px;
}
.workspaces-item-block .workspaces-items .workspaces-item .progress_card_item{
  padding: 3px 8px;
  font-family: 'Poppins';
  border-radius: 25px;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  display: inline-block;
}
.workspaces-item-block .workspaces-items .workspaces-item .progress_card .Complete{
  background: rgba(39, 174, 96, 0.16);
  color: #219653;
}
.workspaces-item-block .workspaces-items .workspaces-item .progress_card .Pending{
  background: rgba(242, 201, 76, 0.16);
  color: #C29D0A;
}
.workspaces-item-block .workspaces-items .workspaces-item .progress_card .Failed{
  background: rgba(227, 92, 42, 0.16);
  color: #d44a4a;
}


.workspaces-item-block .workspaces-items .workspaces-item hr{
  margin: 16px auto;
  border-color: #EFEFEF;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body{
  padding: 0;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh{
  margin-bottom: 16px;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh .harwinder-name,
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh p{
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #0071BD;
  display: block;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh .harwinder-name{
  margin-bottom: 12px;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh .harwinder-name svg,
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh p svg{
  margin-right: 8px;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh .harwinder-name svg {
  width: 16px;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh .harwinder-name svg path{
  fill: #0071BD;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .created-harwinder-singh p{
  color: #828282;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .description{
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.workspaces-item-block .workspaces-items .workspaces-item .card-body .description p{
  overflow: hidden;
color: #646670;
text-overflow: ellipsis;
font-family: 'Noto Sans',sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}

.rs-dropdown ul li {
  box-shadow: none !important;
  border-radius: 0px !important;
  /* margin-top: -5px !important; */
  border: none !important; 
  cursor: default; 
}

.rs-dropdown ul li:hover {
  color: #1364C3; 
}

.workspaces-item-left {
  display: flex;
  align-items: center;
}

.workspaces-item-left .workspaces-item-content {
  padding-left: 10px;
}
.workspaces-item-content h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 7px;
}
.workspaces-item-content p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #898989;
  margin: 0;
}
.workspaces-item-right {
  display: flex;
  align-items: center;
}
.workspaces-item-right p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  margin: 0;
  padding-right: 10px;
}
.workspaces-item-right p svg{
  width: 16px;
  margin-left: 10px;
}
.workspaces-inner-wrraper{
  width: 100%;
}
.workspaces-inner-wrraper .dataTypeName{
  color: #25282F;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 33px;
  position: relative;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.workspaces-inner-wrraper .dataTypeName::after{
  position: absolute;
  content: "";
  left: 0;
  bottom: -3px;
  width: 38px;
  height: 3px;
  background: linear-gradient(0deg, #E4AE40 0%, #E4AE40 100%), #FFF;
}
.workspaces-inner-wrraper .dataTypeName .viewAll{
  color: var(--Blue, #1570B7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  cursor: default;
}
.workspaces-item-block {
  /* width: 100%; */
  width: 95%;
  max-height: 100%;
  overflow-y: auto;
}
.workspaces-item-block::-webkit-scrollbar
{
	width: 4px;
	background-color: #F5F5F5;
}

.workspaces-item-block::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #f1f1f1;
}
.workspaces-block .workspaces-team {
  max-width: 368px;
  width: 100%;
  height: 100%;
}
.workspaces-inner{
  display: flex;
  /* height: calc(100% - 124px); */
  /* height: calc(100% - 80px); */
  gap: 16px;
  margin-bottom: 50px;
}
.team-block {
  /* padding: 20px; */
  max-width: 368px;
  width: 100%;
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 16px rgb(0 0 0 / 4%);
  border-radius: 8px;
  cursor: default ;
  overflow: hidden;
  max-height: 100%;
}
.team-block .team-inner{
  height: 100%;
}
.team-block .team-inner .team-all-item{
  background: #FFFFFF;
  padding: 0 20px 20px;
}
.team-all-item .team-data{
  overflow-y: auto;
  max-height: calc(100vh - 238px);
}
.team-all-item .team-data::-webkit-scrollbar{
	width: 4px;
	background-color: #F5F5F5;
}

.team-all-item .team-data::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #f1f1f1;
}
.team-block .team-inner .no-data{
  padding: 0;
}
.team-block .team-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  padding: 20px 20px 35px;
  background: #FFFFFF;
  margin: 0;
}
.team-block .team-title h2 {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #333333;
  margin: 0;
}
.team-addmember span {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #0071BD;
  display: flex;
  align-items: center;
}
.team-addmember span h6 {
  margin: 0;
  padding-right: 10px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
}
.team-addmember span svg path{
  fill: #0071BD;
}

.team-addmember span h6:hover {
  border-bottom: 1px solid black ;
}

.team-all-item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.team-all-item ul li .team-item {
  display: flex;
  align-items: center;
  width: 100%;
}
.team-all-item ul li .team-item .team-img img {
  width: 42px;
  border-radius: 100px;
  height: 42px;
}
.team-all-item ul li .team-item .team-img {
  padding-right: 10px;
}
.team-item .team-content h3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 7px;
}
.team-item .team-content p {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #898989;
  margin: 0;
}
.team-all-item ul li ~ li {
  border-top: 1px solid #EFEFEF;
  padding-top: 20px;
  margin-top: 20px;
}
.mobile-logo{
  display: none;
}
.toggle-menu {
  display: none;
}
.orderProcessing .import-data-block{
  display: flex;
  /* align-items: center; */
}
.orderProcessing .import-data-block-inner{
  height: auto;
  min-height: auto;
  padding: 0;
  background: transparent;
}
.orderProcessing .import-data-block-inner .container{
  display: flex;
  justify-content: center;
  max-width: 1440px;
}
.orderProcessing .model_ready .container{
  padding: 24px;
  background: white;
}
.orderProcessing .import-data-block-inner .container .ordercard_containt{
  display: flex;
  justify-content: center;
  gap: 36px;
  padding: 24px;
  background: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.orderProcessing .import-data-block-inner .ordercard .cardlabel{
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  padding-left: 12px;
  margin-bottom: 19px;
}
.orderProcessing .ordercard{
  position: relative;
  /* max-width: 440px; */
  width: 420px;
}
.orderProcessing .ordercard .order_card_item{
  min-height: 281px;
}
.orderProcessing .ordercard:not(:last-child):after{
  position: absolute;
  content: "";
  top: 0;
  right: -18px;
  width: 1px;
  height: 100%;
  background: #E0E0E0;
}
.orderProcessing .ordercard .orderstep .orderstep{
  margin-bottom: 24px;
}
.orderProcessing .ordercard .orderstep .rs-steps-item{
  padding: 26px 12px 26px 50px;
  overflow: unset;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-active{
  background: rgba(0, 113, 189, 0.04);
  border-radius: 10px;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-error{
  background: rgba(235, 87, 87, 0.04);
}
.orderProcessing .ordercard .orderstep .rs-steps-item .rs-steps-item-tail{
  top: 40px;
  left: 25px;
  height: 80px;
}
.orderProcessing .ordercard .orderstep .rs-steps-item .rs-steps-item-icon-wrapper{
  width: 16px;
  height: 16px;
  background: #E0E0E0;
  border: none;
  top: 28px;
  left: 18px;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-finish .rs-steps-item-icon-wrapper{
  background: #27AE60;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-finish .rs-steps-item-icon-wrapper .rs-steps-item-icon-finish svg{
  fill: white;
  font-size: 12px;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-finish .rs-steps-item-icon-wrapper .rs-steps-item-icon-finish{
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-finish .rs-steps-item-icon-wrapper .rs-steps-item-icon-finish::before{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  background: #27AE60;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-error .rs-steps-item-icon-wrapper .rs-steps-item-icon-error::before{
  display: none;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-active .rs-steps-item-icon-wrapper{
  width: 28px;
  height: 28px;
  top: 22px;
  left: 12px;
  background: #0071BD;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-error .rs-steps-item-icon-wrapper{
  background: #EB5757;
}
.orderProcessing .ordercard .orderstep .rs-steps-item .rs-steps-item-tail,
.orderProcessing .ordercard .orderstep .rs-steps-item-active .rs-steps-item-tail{
  border-width: 2px;
  border-color: #E0E0E0;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-finish .rs-steps-item-tail{
  border-color: #27AE60;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-active .rs-steps-item-icon-wrapper .rs-steps-item-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-content .rs-steps-item-title{
  display: block;
  padding: 0;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-title .orderProcessingTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-title .orderProcessingTitle .title{
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-active .orderProcessingTitle .title{
  font-weight: 700;
  color: #0071BD;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-status-error .orderProcessingTitle .title{
  color: #EB5757;
}
.orderProcessing .ordercard .orderstep .rs-steps-item-title .orderProcessingTitle .date{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}
.orderProcessing .ordercard .description{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
  width: 100%;
  padding-left: 12px;
}
.fineTunedModel .import-data-block{
  height: auto;
  min-height: auto;
}
.fineTunedModel .import-data-block-inner{
  padding: 0;
  background: transparent;
}
.fineTunedModel .container{
  max-width: 1440px;
}
.fineTunedModel .container .title{
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #333333;
  margin-bottom: 15px;
}
.fineTunedModel .container{
  /* padding: 0; */
  /* background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #E0E0E0; */
}
.fineTunedModel .container .analysis-content{
  padding: 24px 24px 0;
}
.fineTunedModel .container hr{
  margin: 24px auto;
}
.fineTunedModel .container .inference{
  /* padding: 0 24px 24px; */
}
.fineTunedModel .container .fine-content .Analysis{
  position: relative;
  width: calc(32% - 4px);
}
.fineTunedModel .container .fine-content .tabs{
  width: 65%;
}
.fineTunedModel .container .fine-content .Analysis::after{
  position: absolute;
  content: '';
  top: 0;
  right: -20px;
  width: 1px;
  height: 100%;
  background: #E0E0E0;
}
.fineTunedModel .container .fine-content .title{
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  margin-bottom: 16px;
}
.fineTunedModel .container .fine-content .description{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4F4F4F;
  margin-bottom: 20px;
}
.fineTunedModel .container .fine-content .description span{
  font-weight: 700;
  line-height: 16px;
  border-bottom: 1px solid #4F4F4F;
}
.fineTunedModel .container .fine-content .TimeLMspaper{
  padding-left: 18px;
  margin-bottom: 20px;
}
.fineTunedModel .container .fine-content .TimeLMspaper li{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
}
.fineTunedModel .container .fine-content .TimeLMspaper li:not(:last-child){
  margin-bottom: 16px;
}
.fineTunedModel .container .fine-content .TimeLMspaper li span{
  line-height: 16px;
  border-bottom: 1px solid #333333;
}
.fineTunedModel .container .fine-content .Labels{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 20px;
}
.fineTunedModel .container .fine-content .Labels span{
  font-weight: 700;
}
.fineTunedModel .container .fine-content .TweetNLP{
  margin: 0;
}
.fineTunedModel .container .fine-content .tabs .nav .nav-link{
  background: none;
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 0;
}
.fineTunedModel .container .fine-content .tabs .nav .active{
  font-weight: 600;
  color: #0071BD;
  border-color: #0071BD;
}
.fineTunedModel .container .fine-content .tab-content .tab-pane .recharts-wrapper{
  max-width: 100%;
  width: 100% !important;
}
.fineTunedModel .container .fine-content .tab-content .tab-pane .recharts-wrapper .recharts-surface,
.fineTunedModel .container .fine-content .tab-content .tab-pane .recharts-wrapper .recharts-legend-wrapper{
  width: 100% !important;
}
.fineTunedModel .container .fine-content .tab-content .table{
  border-spacing: 0 16px;
  border-collapse: separate;
}
.fineTunedModel .container .fine-content .tab-content .ModalTableHeaderCell{
  width: 100%;
}
.fineTunedModel .container .fine-content .tab-content .rs-table-cell-group{
  display: flex;
}
.fineTunedModel .container .fine-content .tab-content .rs-table-cell{
  width: 100% !important;
  left: 0 !important;
  position: relative;
}
.fineTunedModel .container .fine-content .tab-content .table thead tr th{
  background: #F7F7F7;
  border: none;
  color: #808B97;
}
.fineTunedModel .container .fine-content .tab-content .table thead tr th:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.fineTunedModel .container .fine-content .tab-content .table thead tr th:last-child{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.fineTunedModel .container .fine-content .tab-content .table tbody tr td{
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  color: #0B114F;
  border-top: none;
}
.fineTunedModel .container .fine-content .tab-content .table tbody tr th{
  border-top: none;
}
.fineTunedModel .container .fine-content .tab-content .table tbody tr td,
.fineTunedModel .container .fine-content .tab-content .table tbody tr th{
  border-bottom: 1px solid #E0E0E0;  
}
.chat_contener .navigation-inner .logo,
.deliveryTopics_contener .navigation-inner .logo{
  display: none;
}
.chat-block .chat_data{
  background: #F8FAFC;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
  margin: 20px 0 0;
}
.chat-block .chat_data .chat_mobile{
  max-width: 320px;
  width: 100%;
  background: #2D2D41;
  border-radius: 42.667px;
  height: 676px;
  position: relative;
}
.chat-block .chat_data .chat_mobile::after{
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background: white;
  transform: translate(-50%, -50%);
  border-radius: 42.667px;
}
.chat-block .chat_data .chat_mobile .navbar{
  fill: #2D2D41;
  position: relative;
  width: 100%;
  z-index: 1;
}
.chat-block .chat_data .chat_mobile .header{
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  padding: 16px;
  border-bottom: 1px solid #EDEDED;
  margin: 0 8px;
}
.chat-block .chat_data .chat_mobile .chat_body{
  height: calc(100% - 200px);
  background: #F3FAFF;
  z-index: 1;
  position: relative;
  margin: 0 8px;
  padding: 16px;
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  overflow-y: auto;
}
.chat-block .chat_data .chat_mobile .chat_body::-webkit-scrollbar {
  display: none;
}
.chat-block .chat_data .chat_mobile .chat_body .box{
  max-width: 245px;
  width: fit-content;
  padding: 10px;
  background: #FFFFFF;
  border-radius: 16px 16px 16px 0px;
  margin: 0 auto 10px 0;
}
.chat-block .chat_data .chat_mobile .chat_body .box:last-child{
  margin-bottom: 0;
}
.chat-block .chat_data .chat_mobile .chat_body .box .messages{
  font-family: 'Mulish';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0F1828;
}
.chat-block .chat_data .chat_mobile .chat_body .box .time{
  font-family: 'Lato';
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #ADB5BD;
  display: block;
  margin-top: 8px;
}
.chat-block .chat_data .chat_mobile .footer{
  position: relative;
  z-index: 1;
  border-top: 1px solid #EDEDED;
  margin: 0 8px;
}
.chat-block .chat_data .chat_mobile .footer svg{
  fill: #0F1828;
  width: 100%;
}
.chat-block .chat_data .chat_mobile .footer .send_message{
  display: flex;
  align-items: center;
  padding: 10px 16px 0;
}
.chat-block .chat_data .chat_mobile .footer .send_message .plusIcon{
  margin-right: 12px;
}
.chat-block .chat_data .chat_mobile .footer .send_message .form-control{
  background: #F7F7FC;
  border-radius: 4px;
  border: none;
  padding: 6px 8px;
  height: 36px;
  resize: none;
  font-family: 'Mulish';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #0F1828;
}
.chat-block .chat_data .chat_mobile .footer .send_message .form-control::-webkit-scrollbar {
  display: none;
}
.chat-block .chat_data .chat_mobile .footer .send_message .sendIcon{
  margin-left: 12px;
}
.chat-block .chat_data .chat_mobile .header .back_icon,
.chat-block .chat_data .chat_mobile .header .search_icon{
  margin-right: 8px;
  cursor: pointer;
}
.chat-block .chat_data .chat_mobile .header .moreIcon{
  cursor: pointer;
}
.chat-block .chat_data .chat_mobile .header .title{
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #0F1828;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
}
.chat-block .chat_data .chat_mobile .header .icon_bar{
  display: flex;
  align-items: center;
}
.deliveryTopics_contener .navigation-inner .navigation-left{
  max-width: 100%;
  width: 100%;
  text-align: center;
}
.chat-block{
  background: white;
  min-height: 100vh;
}
.deliveryTopics-block{
  background: white;
  height: 100vh;
}
.deliveryTopics-block .deliveryTopics_type{
  margin: 20px 0;
}
.deliveryTopics-block .deliveryTopics_type{
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deliveryTopics-block .deliveryTopics_type .type_item:nth-child(odd){
  width: 232px;
}
.deliveryTopics-block .deliveryTopics_type .type_item{
  padding: 10px 16px;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.deliveryTopics-block .deliveryTopics_type .type_item .number{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  font-weight: 700;
  font-family: 'DM Sans';
  display: flex;
  align-items: center;
  justify-content: center;
}
.deliveryTopics-block .deliveryTopics_type .type_item span{
  color: #333333;
  font-weight: 500;
  font-family: 'DM Sans';
}
.deliveryTopics-block .deliveryTopics_type .delay_item .number{
  background: rgba(242, 153, 74, 0.1);
  color: #F2994A;
}
.deliveryTopics-block .deliveryTopics_type .incorrect_item .number{
  background: rgba(47, 128, 237, 0.1);
  color: #2F80ED;
}
.deliveryTopics-block .deliveryTopics_type .failed_item .number{
  background: rgba(235, 87, 87, 0.1);
  color: #EB5757;
}
.deliveryTopics-block .deliveryTopics_type .damage_item .number{
  background: rgba(187, 107, 217, 0.1);
  color: #9B51E0;
}
.deliveryTopics-block .deliveryTopics_type .lost_item .number{
  background: rgba(242, 201, 76, 0.1);
  color: #C59D24;
}
.deliveryTopics-block .deliveryTopics_type .delay_active{
  border-color:#F2994A;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
}
.deliveryTopics-block .delivery_content{
  padding: 16px;
  background: #F8FAFC;
  border-radius: 12px;
  min-height: 653px;
}
.deliveryTopics-block .delivery_content .title{
  font-family: 'DM Sans';
  font-weight: 700;
  color: #000000;
  font-size: 16px;
  margin-bottom: 16px;
}
.deliveryTopics-block .delivery_content .delivery_card_content{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item{
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 16px;
  max-width: 257px;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item .post_time{
  margin: 4px 0 16px;
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4F4F4F;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item .title{
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 8px;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item .description{
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4F4F4F;
  margin-bottom: 16px;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item .avatar_content{
  display: flex;
  align-items: center;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item .avatar_content .avatar_icon{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item .avatar_content .avatar_icon img{
  width: 100%;
}
.deliveryTopics-block .delivery_content .delivery_card_content .delivery_card_item .avatar_content span{
  font-family: 'DM Sans';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}
.table tbody tr td, .table tbody tr th {
  padding-top: 0 !important;
}
.inference .Classification-computation-content .Classification-computation-time{
  background: white;
  border-radius: 16px;
  padding: 16px 0px 0px 5px;
  height: 330px;
  position: relative;
}
.inference .Classification-computation-content .Classification-computation-time::after{
  content: "";
  position: absolute;
  height: 100%;
  background: #E0E0E0;
  width: 1px;
  right: -19px;
  top: 0;

}
.inference .Classification-computation-time .beast_mode{
  text-align: right;
  color: #333333;
  font-weight: 700;
  padding-right: 36px;
  margin-bottom: 10px;
}
.custom-switch .custom-control-label::before {
  cursor: pointer;
  right: -35px !important;
  left: unset;
}
.custom-switch .custom-control-label::after {
  cursor: pointer;
  right: -21px;
  left: auto;
}
.inference .Classification-computation-content{
  display: flex;
  gap: 38px;
}
.inference .Classification-computation-content{
  min-height: 430px;
}
.inference .Classification-computation-content .Classification-computation-time,
.inference .Classification-computation-content .Computation-time{
  position: relative;
}
.inference .Classification-computation-content .Computation-time::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: #E0E0E0;
  top: 0;
  right: -19px;
}
.inference .Classification-computation-content .Classification-computation-time .Classification .title{
  margin: 0;
}
/* .inference .Classification-computation-content .Classification-computation-time::after,
.inference .Classification-computation-content .Computation-time::after{
  position: absolute;
  content: "";
  top: 0;
  right: -19px;
  width: 1px;
  height: 100%;
  background: #E0E0E0;
} */
.inference .Classification-computation-content .Classification-computation-time,
.inference .Classification-computation-content .Computation-time{
  width: 50%;
}
.inference .Classification-computation-content .Computation-time{
  padding: 20px;
  border-radius: 12px;
  background: linear-gradient(0deg, rgba(0, 113, 189, 0.08) 0%, rgba(0, 113, 189, 0.08) 100%), rgba(255, 255, 255, 0.20);
  height: 330px;
}
.inference .Classification-computation-content .Computation-time .beast_mode{
  padding: 0;
}
/* .inference .Classification-computation-content .Computation-time{
  background: linear-gradient(0deg, rgba(0, 113, 189, 0.08), rgba(0, 113, 189, 0.08)), rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 20px;
} */
.inference .Classification-computation-content .Computation-time .dropdown-toggle{
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  background: #0071BD;
  color: white;
  padding: 8px 10px;
}
.inference .Classification-computation-content .Computation-time .dropdown-menu{
  padding: 14px;
  right: 0;
  left: unset;
  min-width: 150px;
}
.inference .Classification-computation-content .Computation-time .dropdown-menu .dropdown-item:hover{
  background-color: transparent;
}
.inference .Classification-computation-content .Computation-time .dropdown-menu .dropdown-item{
  padding: 4px 0;
  font-size: 14px;
  font-weight: 700;
}
.inference .Classification-computation-content .Computation-time .dropdown-menu hr{
  margin: 14px 0;
}
.inference .Classification-computation-content .Computation-time .dropdown-menu svg{
  margin-right: 6px;
}
.inference .Classification-computation-time .title{
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.inference .Classification-computation-time .Classification{
  margin-bottom: 10px;
}
.inference .title{
  display: flex;
  align-items: center;
}
.inference .title svg{
  margin-left: 16px;
}

.inference .Classification-computation-time .Classification{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inference .Classification-computation-time .Classification-containt{
  width: 80%;
  margin: auto;
  /* min-height: calc(100vh - 24px);
  height: 842px; */
}
.inference .Classification-computation-time .AdvancedBtnGroup{
  display: flex;
  gap: 8px;
  margin-bottom: 9px;
}
.inference .Classification-computation-time .AdvancedBtnGroup .btn{
  padding: 6px 10px;
  border: 1px solid #E0E0E0;
  color: #0071BD;
  font-style: 12px;
  font-weight: 700;
  border-radius: 6px;
}
.inference .Classification-computation-time .AdvancedBtnGroup .active{
  background: #0071BD;
  color: white;
}

/*  chatbox start time  ======= (( CAN BE DELETED LATER ))  */ 



.inference .Classification-computation-time .chatBox .chatData{
  padding-right: 10px;
}
.inference .Classification-computation-time .chatBox .chatData::-webkit-scrollbar{
  width: 5px;
  background-color: transparent;
}
.inference .Classification-computation-time .chatBox .chatData::-webkit-scrollbar-track{
	border-radius: 10px;
	background-color: transparent;
}
.inference .Classification-computation-time .chatBox .chatData::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #e1e1e1;
}
.inference .Classification-computation-time .chatBox{
  min-height: calc(100vh - 400px);
  height: 200px;
  display: flex;
  align-items: end;
  padding-bottom: 77px;
}
.inference .Classification-computation-time .chatBox .msg{
  border: 1px solid #E0E0E0;
  padding: 16px 16px 20px;
  border-radius: 16px;
  display: flex;
  align-items: start;
  margin-bottom: 16px;
}
.inference .Classification-computation-time .chatBox .msgadd{
  background: #F0F6FB;
  border-color: #F0F6FB;
  width: 80%;
  margin-left: auto;
}
.inference .Classification-computation-time .chatBox .msg img{
  width: 24px;
  margin-right: 12px;
}
.inference .Classification-computation-time .chatBox .msg p{
  color: #333333;
  font-style: 14px;
  font-weight: 500;
}
.inference .Classification-computation-time .msgBox{
  display: flex;
  justify-content: center;
}
.inference .Classification-computation-time .msgBox .containt{
  background: #FAFAFA;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  padding: 13px 16px;
  display: flex;
  width: 100%;
}
.inference .Classification-computation-time .msgBox .containt img{
  width: 24px;
}
.inference .Classification-computation-time .msgBox .containt input{
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 12px;
  font-style: 14px;
  font-weight: 500;
  color: #4F4F4F;
}
.inference .Classification-computation-time .msgBox .containt textarea{
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  padding-left: 12px;
  font-style: 14px;
  font-weight: 500;
  color: #4F4F4F;
}
.inference .Classification-computation-time .msgBox .sendBtn{
  background: #0071BD;
  padding: 13px;
  border-radius: 16px;
  margin-left: 19px;
}

.inference .dropdown button{
  background-color: transparent;
  border-color: #E0E0E0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4F4F4F;
  border-radius: 8px;
}

.inference .increasing{
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 14px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  /* margin-bottom: 20px; */
  width: 100%;
}
/* .inference .increasing::-webkit-scrollbar {
  display: none;
} */
.inference .compute_btn{
  border-radius: 8px;
  padding: 10px 18px !important;
  /* font-weight: 500; */
  font-size: 14px!important;
  line-height: 18px;
}


/* chatbox finish */  

.webURL .container{
  height: 100%;
  display: flex;
}
.webURL .webURL-top-block{
  width: 60%;
  margin: auto;
}
.webURL .webURL-top-block .csv-title{
  margin-bottom: 84px;
}
.webURL .webURL-top-block .upload_URL{
  margin-bottom: 34px;
}
.webURL .webURL-top-block .upload_URL label{
  display: block;
  color: #333333;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.webURL .webURL-top-block .upload_URL input{
  padding: 14px 16px;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  width: 100%;
  margin-bottom: 8px;
}
.webURL .webURL-top-block .upload_URL .add_new_URL{
  display: flex;
  justify-content: end;
}
.webURL .webURL-top-block .upload_URL .add_new_URL .btn{
  background: #0071BD24;
  padding: 8px 16px;
  color: #0071BD;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
}
.webURL .webURL-top-block .connectors-button{
  justify-content: center;
}
.webURL .webURL-top-block .connectors-button .nxtBtn{
  flex: 0;
}

.Computation-time .title{
  margin-bottom: 24px;
}
.Computation-time .progress-bar{
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.Computation-time .progress-bar:not(:last-child){
  margin-bottom: 28px;
}
.Computation-time .progress-bar .progress{
  background: #EAEEF6;
  border-radius: 3px;
  height: 6px;
  width: 100%;
}
.Computation-time .progress-bar .progress .bar,
.Computation-time .progress-bar2 .progress .bar,
.Computation-time .progress-bar3 .progress .bar{
  border-radius: 3px;
}
.Computation-time .progress-bar .progress .bar{
  width: 30%;
  background: #EB5757;
}
.Computation-time .progress-bar2 .progress .bar{
  background: #0071BD;
  width: 50%;
}
.Computation-time .progress-bar3 .progress .bar{
  background: #27AE60;
  width: 10%;
}
.Computation-time .progress-bar .label{
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-right: 16px;
  color: #4F4F4F;
  min-width: 80px;
  text-align: start;
}
.Computation-time .progress-bar .progress-number{
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #171725;
  margin-left: 16px;
  min-width: 36px;
  text-align: start;
}
.inference .json-output{
  background: #1C1D1F;
  border: 1px solid #1C1D1F;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  height: 330px;
}
.inference .json-output .Maximize svg{
  margin-right: 8px;
}
.configure-sidebar {
  /* width: 500px !important; */
}
.configure-sidebar .rs-drawer-body {
  align-items: center;
  justify-content: center;
  padding: 24px !important;
  margin: 0;
}
.configure-sidebar .rs-drawer-body .rs-drawer-body-close{
  display: none;
}
.configure-sidebar .checkout-section .description{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* margin-bottom: 16px; */
  color: #333333;
  text-align: center;
}

.configure-sidebar .checkout-section .checkout-btn-section{
  display: flex;
  align-items: center;
  justify-content: center;
}
.configure-sidebar .checkout-section .checkout-btn{
  border: none;
  outline: none;
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 24px;
  background: #0071BD;
  border-radius: 25px;
}
.rs-drawer-body .w-100{
  width: 100%; 
}
.configure-sidebar hr{
  margin: 24px 0;
}
.configure-sidebar .configure-form .advanced-setting .title{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* margin-bottom: 16px; */
  margin-bottom: 2px;
  color: #000000;
  cursor: pointer;
}
.configure-sidebar .configure-form .advanced-setting .advanced-tab{
  display: flex;
  align-items: center;
  gap: 12px;
  
}
.configure-sidebar .configure-form .advanced-setting .advanced-tab .form-group input{
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  
}
.configure-sidebar .configure-form .advanced-setting .advanced-tab .form-group{
  border-radius: 8px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
  
  border: 1px solid #BEBEBE;
  background-color: #F7F7F7 !important;
  color: #3F3F3F;
  
}

.configure-sidebar .configure-form .advanced-setting .advanced-tab .unavailable{
  background-color: rgb(237, 234, 234);
  border: none ; 

}

.configure-sidebar .configure-form .advanced-setting .advanced-tab .unavailable label {
  color: gray; 
}

.configure-sidebar .configure-form .advanced-setting .advanced-tab .unavailable div {
  position: absolute;  /* Position the footer at the bottom of the form-group */
  bottom: 0;
  left: 0;
  width: 100%;  /* Ensure it spans the entire width */
  text-align: center;  /* Center the text */
  font-size: 10px;  /* Make the text smaller */
  color: #d9534f  /* Use a color that indicates something's amiss */
}

.configure-sidebar .configure-form .advanced-setting .advanced-tab .active{
  /* border-color: #27AE60;
  background: rgba(39, 174, 96, 0.08); */
  border-color: #3e4784;
  background: #E6EBFB;


}
.configure-sidebar .configure-form .advanced-setting .advanced-tab .active svg{
  margin-right: 6px;
}
.configure-sidebar .configure-form .advanced-setting .advanced-tab .form-group label{
  margin: 0;
  padding: 10px 0;
  cursor: pointer;
}
.configure-sidebar .configure-form .advanced-setting .advanced-tab .active label{
  cursor: pointer;
  padding: 10px 0;
}
.configure-sidebar .configure-form .epochs .form-geoups{
  gap: 16px;
  margin-bottom: 24px;
  display: flex;
}
.configure-sidebar .configure-form .epochs .form-geoups .form-group{
  width: 50%;
  margin: 0;
}
.configure-sidebar .configure-form .epochs .form-geoups .form-group .title{
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 6px;
  display: block;
}
.configure-sidebar .configure-form .footer{
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.configure-sidebar .configure-form .footer .btn{
  padding: 17px 47px;
  border-radius: 25px;
  font-weight: 700;
  font-size: 13px;
  line-height: 17px;
}
.configure-sidebar .configure-form .footer .cancel-btn{
  background: rgba(224, 224, 224, 0.4);
  color: #333333;
}
.configure-sidebar .configure-form .footer .confirm-btn{
  background: #0071BD;
  color: #FFFFFF;
}

.errorTextForm {
    color: #d44a4a;
    font-size: 0.75rem;
}
.prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-subtitle{
  white-space: wrap !important;
}
.prompt-suggestion-grow{
  top: 0% !important;
  display: flex;
  align-items: center;
}
.prompt-suggestion-grow .prompt-suggestion-container{
  position: static !important;
  padding: 0 !important;
  margin: 0 !important;
  transform: none !important;
  max-width: 100% !important;
} 
@media(max-width:1400px){
  .workspaces-inner .workspaces-team {
    width: 30%;
  }
  .workspaces-item-block {
    width: 70%;
  }
  .workspaces-block {
    padding: 24px;
  }
  .workspaces-block .workspaces-item-block {
    width: 100%;
  }
  .workspaces-item-block{
    grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
  }
  .orderProcessing .ordercard{
    width: auto;
  }
  .orderProcessing .ordercard .orderstep .rs-steps-item-title .orderProcessingTitle{
    height: 21px;
  }
  .prompt-suggestion-grow .prompt-suggestion-container{
    width: 100%;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid{
    display: flex;
    flex-direction: column;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-title{
    white-space: wrap;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-subtitle{
    white-space: wrap;
  }
}
@media(max-width: 1240px){
  .orderProcessing .import-data-block-inner .container{
    padding: 14px;
    gap: 30px;
  }
  .orderProcessing .ordercard:not(:last-child):after{
    right: -15px;
  }
  .orderProcessing .ordercard .orderstep .rs-steps-item-title .orderProcessingTitle .title{
    font-size: 15px; 
  }
}
@media(max-width:1199px){
  .workspaces-item-block {
    width: 60%;
  }
  .workspaces-inner .workspaces-team {
    width: 40%;
    max-width: none;
  }
  .workspaces-inner .workspaces-team .team-block{
    max-width: none;
  }
  /* .WorkspaceType .type-content{
    max-width: 70%;
  } */
}
@media(max-width:1075px){
  .orderProcessing .import-data-block-inner .container{
    flex-wrap: wrap;
    max-width: 60%;
    margin-bottom: 20px !important;
  }
  .orderProcessing .ordercard:not(:last-child):after{
    top: auto;
    bottom: -15px;
    right: 0;
    width: 100%;
    height: 1px;
  }
  .orderProcessing .import-data-block{
    height: auto;
    min-height: auto;
  }
  .orderProcessing .import-data-block-inner{
    height: auto;
    margin-top: 20px !important;
  }
  .orderProcessing .import-data-block-inner .container {
    margin: 0 auto !important;
  }
  .import-data-block .import-data-block-inner .Preparation-container{
    flex-wrap: wrap;
  }
  .orderProcessing .ordercard{
    width: 100% !important;
  }
  .orderProcessing .ordercard .order_card_item{
    min-height: auto;
  }
}
@media(max-width:991px){
  .sidepanel-block {
    left: 0;
    opacity: 0;
    top: 87px;
    position: fixed;
    visibility: hidden;
    height: 100%;
    transform: translateX(-290px);
    transition: 0.5s all ease-in-out;
  }
  .sidepanel-block.Active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: 0.5s all ease-in-out;
    z-index: 10;
  }
  .sidepanel-block .sidepanel-inner .sidepanel-profile{
    padding: 15px 30px;
  }
  .home-right {
    width: 100%;
    padding-left: 0;
  }
  .mobile-logo{
    display: block;
  }
  .navigation-left {
    display: flex;
    align-items: center;
  }
  .navigation-content {
    /* padding-left: 20px; */
    /* margin-left: -50px !important; */
  }
  .toggle-menu {
    display: block;
    padding-right: 25px;
  }
  .navigation-block {
    padding: 20px;
  }
  .workspaces-block {
    padding: 30px 20px;
    flex-wrap: wrap;
  }
  .navigation-notification {
    padding-right: 20px;
    
  }
  .sidepanel-logo.sidepanel-item {
    display: none;
    ;

  }
  .WorkspaceType .type-content{
    grid-template-columns: auto;
  }
  .home-right{
    height: auto;
  }
  .workspaces-team, .team-block{
    max-width: 100% !important;
  }
  .fineTunedModel .import-data-block{
    height: auto;
  }
  .import-data-container .fine-content .Classification-computation-content{
    flex-wrap: wrap;
  }
  .import-data-container .fine-content .Classification-computation-content .Classification-computation-time,.import-data-container .fine-content .Classification-computation-content .json-output,.import-data-container .fine-content .Classification-computation-content .Computation-time{
    width: 100%;
  }
  .inference .Classification-computation-content .Classification-computation-time{
    padding: 16px;
  }
  .import-data-container .fine-content .Classification-computation-content .Computation-time{
    overflow-y: auto;
  }
  .fineTunedModel .import-data-block .import-data-block-inner .import-data-container .fine-content{
    flex-wrap: wrap;
  }
  .fineTunedModel .import-data-block .import-data-block-inner .import-data-container .fine-content .tabs{
    width: 100%;
  }
  .fineTunedModel .import-data-block .import-data-block-inner .import-data-container .fine-content .Analysis{
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
  .fineTunedModel .container .fine-content .Analysis::after{
    position: static;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-title{
    font-size: 12px;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-subtitle{
    line-height: 15px;
    font-size: 10px;
  }
  .custom-background-copilot .Editer_container{
    flex-wrap: wrap !important;
  }
  .custom-background-copilot .Editer_container .chatBox{
    width: 100% !important;
  }
  .custom-background-copilot .Editer_container .Computation-time{
    width: 100% !important;
  }

}
@media(max-width:767px){
  .workspaces-block{
    height: auto;
  }
  .workspaces-inner{
    display: block;
    height: auto;
  }
  .workspaces-item-block {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .workspaces-inner .workspaces-team{
    width: 100%;
    padding: 0;
  }
  .navigation-left h2 {
    /* font-size: 16px; */
    font-size: 18px !important;
  }
  .WorkspaceType .type-content{
    max-width: 100%;
  }
  .workspaces-item-block {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }
  .orderProcessing .import-data-block-inner .container{
    max-width: 100%;
  }
  .inference .Classification-computation-content{
    flex-wrap: wrap;
    gap: 28px;
  }
  .inference .Classification-computation-content .Classification-computation-time::after{
    display: none;
  }
  .inference .Classification-computation-content .Classification-computation-time,
  .inference .Classification-computation-content .Computation-time{
    width: 100%;
  }
  #docsDrawer{
    max-width: 100%;
    width: 100%;
  }
}
@media(max-width:599px){
  .notification-block {
    min-width: 300px;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-title{
    white-space: wrap;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-subtitle{
    white-space: wrap;
  }
  .fineTunedModel .container .fine-content .tab-content .tab-pane .recharts-wrapper{
    height: 230px !important;
  }
}
@media(max-width:575px){
  .navigation-content {
    display: none;
  }
  .navigation-inner .logo svg {
    display: block;
  }
  .navigation-profiles-inner h3{
    display: none;
  }
  .navigation-profiles-inner .navigation-close {
    padding-left: 10px;
  }
  .navigation-block {
    /* padding: 15px !important; */
    /* padding: 0px; */
  }
  .sidepanel-block {
    top: 74px;
    height: calc(100% - 74px)
  }
  .fineTunedModel .import-data-block{
    padding: 24px 0;
  }
  .fineTunedModel .import-data-block .fine-content{
    padding: 16px;
  }
  .advanced-setting{
    margin-bottom: 20px;
  }
  .advanced-tab{
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .advanced-tab .form-group {
    width: 100%;
    margin-bottom: 4px !important;
    flex: auto !important;
  }
}
@media(max-width:499px){
  .workspaces-title-button{
    display: block;
  }
  .workspaces-title {
    padding-bottom: 15px;
  }
  .workspaces-item-block ul li{
    display: block;
  }
  .workspaces-item-right {
    padding-top: 10px;
    justify-content: space-between;
  }
  .epochs .form-geoups{
    flex-wrap: wrap;
    margin-bottom: 16px !important;
  }
  .epochs .form-geoups .form-group{
    width: 100% !important;
  }
  .import-data-container .fine-content .Classification-computation-content .Computation-time{
    height: 230px;
  }
  .orderProcessing .ordercard .orderstep .rs-steps-item-title .orderProcessingTitle .title{
    font-size: 12px;
  }
}
@media(max-width:399px){
  .notification-block {
    right: -66px;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-title{
    font-size: 9px;
    line-height: 14px;
  }
  .prompt-suggestion-grow .prompt-suggestion-container .prompt-suggestion-grid .prompt-suggestion-subtitle{
    line-height: 9px;
    font-size: 8px;
  }
}
/* end HOME Page */




.hover-underline-animation {
  display: inline-block;
  position: relative;
  /* color: #0087ca; */
  /* font-weight: bolder; */
  /* letter-spacing: 0.03rem ; */
  cursor: default;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.homepage-tag {

  display: inline-block;
  font-size: 0.75rem !important;
  padding: 0.1rem 0.3rem !important;
  border-radius: 0.2rem !important;
  color: #4F4F4F;
  font-family: 'Poppins';
  font-weight: 500;
  border: 1px solid #4F4F4F;
}

/* teal tag style */
.homepage-tag.teal {
  /* background-color: #D7EAEA !important; */
  color: #219897 !important;
  border: 0.5px solid #1E6A6A !important;
} 

/* indigo tag style */
.homepage-tag.indigo {
  background-color: #D1D9FF !important;
  color: #3F51B5 !important;
  border: 0.5px solid #283593 !important;
} 

/* pink tag style */
.homepage-tag.pink {
  /* background-color: #FCE4EC !important; */
  color: #E91E63 !important;
  border: 0.5px solid #880E4F !important;
} 


.inactive-card-selection-task-type {
  background-color: #f0f0f0 !important;
  cursor: not-allowed;
}
.coming-soon-text {
    font-size: 10px;
    font-style: italic;
    /* color: #ff0000; */
    color: #FF00FF;
    text-transform: uppercase;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination__button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  color: #333;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 4px;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-weight: bold;
}

.pagination__button:hover {
  background-color: #ddd;
}

.pagination__button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination__icon {
  margin-right: 5px;
  margin-left: 5px; 
}

/*
.api-keys-section {
  display: flex;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
}

.api-section {
  margin-right: 10px;
}

.api-section-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.api-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.api-input-small {
  width: 60%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.save-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #0477f5;
  color: #fff;
  cursor: pointer;
}

.revoke-button{
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid #e63946;
  color: #e63946;
}
*/

.api-keys-section {
  /* display: flex; */
  /* align-items: flex-start; */
  /* background-color: #f0f0f0; */
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  /* height: 80vh; */
  flex-wrap: wrap;
  gap: 10px;
}

.api-section {
  flex-grow: 1;
  /* display: flex; */
  margin-right: 10px;
}

.api-section-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.api-input-filled {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.api-input-unfilled {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.save-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #0477f5;
  color: #fff;
  cursor: pointer;
}

.revoke-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid #e63946;
  color: #e63946;
}

.no-api-keys-sticky-warning-message {
  position: sticky;
  top: 0;
  font-size: 14px;
}


.no-api-keys-sticky-warning-message {
    position: sticky;
    top: 0;
    font-size: 14px;
}

.underline-link {
  text-decoration: underline !important;
}


.revoke-modal-container {
  /* position: fixed; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.revoke-modal {
  background-color: #fff;
  /* max-width: 400px; */
  padding: 10px;
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

.revoke-modal-header {
  text-align: center;
  margin-bottom: 10px;
}

.revoke-modal-title {
  font-size: 18px;
  margin: 0;
}

.revoke-modal-body {
  margin-bottom: 20px;
  
}

.revoke-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.revoke-modal-button {
  border: none;
  border-radius: 3px;
  padding: 8px 16px;
  cursor: pointer;
}

.revoke-modal-confirm {
  background-color: #2196f3 !important;
  color: #fff !important;
  margin-right: 8px;
}

.revoke-modal-cancel {
  background-color: #ccc;
  color: #333;
}

.account-avatar-circle {
  background-color: #f2f2f2;
  transition: border-color 0.3s ease;
  border: 2px solid transparent;
}

.account-avatar-circle:hover{
  border-color: #8c8c8c;
}

.workspace-item-Data-card-background {
  background-color : #e2eeee !important ; 
  box-shadow: 0px 4px 8px #c7dddd !important;
}

.workspace-item-Data-card-background > hr {
  border-bottom: 0.5px solid #b4d5d5 !important
}

.workspace-item-Fine-tune-card-background {
  background-color : #ecefff !important ; 
  /* border: 0.5px solid #c5c9e4  !important; */
  box-shadow: 0px 4px 8px #c5c9e4 !important;
}

.workspace-item-Fine-tune-card-background > hr {
  border-bottom: 0.5px solid #D9dcee !important
}

.workspace-item-App-card-background {
  background-color : #Ecd1d9 !important ; 
  border: 0.5px solid #c7dddd  !important;
}

.workspace-item-App-card-background > hr {
  border-bottom: 0.5px solid #Ecd1d9 !important
}

.one-click-finetune {
  /* appearance: none; */
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 32px;
  font-size: 14px;
  /* font-family: Arial, sans-serif; */
  background-color: #fff;
  color: #555;
  cursor: pointer;
}

.one-click-finetune option {
  padding: 8px;
  background-color: #fff;
  color: #555;
}

.one-click-finetune option:hover {
  background-color: #f5f5f5;
}

.one-click-finetune option:checked {
  background-color: #eaeaea;
}

.one-click-finetune:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.completion-advanced-options-button {
  background-color: #e0e0e0 !important;
  border: none !important;
  border-radius: 3px !important;
  color: #333333 !important;
  cursor: pointer !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 6px 10px !important;
  text-decoration: none !important;
  transition: background-color 0.3s ease !important;
}

.completion-advanced-options-button-active {
  background-color: #EEF4FA!important;
  border: none !important;
  color: #007bff !important;
  cursor: pointer !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 6px 10px !important;
  text-decoration: none !important;
  transition: color 0.3s ease !important;
}

.completion-advanced-options-button:hover {
  background-color: #bdbdbd !important;
}

.completion-advanced-options-button:focus {
  outline: none !important;
  background-color: #bdbdbd !important;
}

.discover-card-description-title {
  color: #333;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
  text-decoration: none !important;
}

.discover-finetunes-title-link {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;

}

.error-log-failed-job-exception {
  max-height: 80px;
  overflow-y: auto;
  /* border: 1px solid var(--rs-steps-state-error); */
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(235, 87, 87, 0.04);
}

.error-log-failed-job-exception::-webkit-scrollbar {
  width: 6px;
}

.error-log-failed-job-exception::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.error-log-failed-job-exception::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.error-log-failed-job-exception::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.highlight-recommended-model-selection {
  /* background-color: #FD5B78; */
  background-color: #e0f0e5;
  /* color: #FFF; */
  color: #1d9551;
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 1px;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25); */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-right: 3px;
  font-size: 12px;
}

.training-cost-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.training-cost-item {
  margin: 10px;
  font-size: 16px;
  /* text-align: center; */
}

.training-cost-base-model {
  font-weight: bold;
}

.training-cost-item .training-cost-price {
  margin-left: 5px;
}

.options-workspace-cards {
  font-size: 14px;
}

.options-workspace-cards:hover{
  text-decoration: underline;
}

.custom-support-section {
  position: fixed;
  left: 10px;
  bottom: 10px;
  /* background-color: #e6e6e6;  */
  background-color: #9b59b6;
  font-weight: 500;
  
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  z-index: 3;
}


.copilot-copy-feature {
  color: #0087ca;
}


.tabs-beast-mode .nav .nav-link{
  background: none;
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 0;
}
.tabs-beast-mode .nav .active{
  font-weight: 600;
  color: white;
  border-color: white;
}

.tiny-mce-editor-save-button {
  margin-top: 10px;
  padding: 4px 10px;
  border-radius: 4px;
  border: none;
  color: #0071BD;
  background-color: white;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  transition: background-color 0.3s;
}

.tiny-mce-editor-saved-button {
  margin-top: 10px;
  padding: 4px 10px;
  border-radius: 4px;
  border: none;
  background-color: #4CAF50; /* A green shade to signify success */
  color: white;
  cursor: not-allowed; /* Indicates that no further action can be taken */
  font-size: 12px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
  transition: background-color 0.3s;
}

.tiny-mce-editor-save-button:hover {
  background-color: #afb4b8;; /* Slightly darker green when hovered */
}

.configure-ft-slider {
  /* width: 100%; */
  width: 95%;
  -webkit-appearance: none;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.configure-ft-slider:hover {
  opacity: 1;
}

.configure-ft-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /* background: #4CAF50; */
  /* background: #d3d3d3; */
  background: #727070 ;
  cursor: pointer;
}

.configure-ft-slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  /* background: #4CAF50; */
  background: #6a6565 ;
  cursor: pointer;
}

.configure-ft-slider-labels {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  font-size: small;
  color: #d3d3d3
}

.configure-ft-slider-label-selected {
  font-weight: 500;
  color: #6a6565 
}

.parsed-content .import-data-block .import-data-block-inner .import-data-container{
  border-radius: 12px;
  border: 1px solid var(--Gray-5, #E0E0E0);
  background: #FFF;
}

.import-data-block .import-data-block-inner .Preparation-container{
  border-radius: 8px;
  background: #FFF; 
  box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.04);
  padding: 24px;
  display: flex;
  justify-content: center;
  gap: 36px;
} 

.custom-background-copilot .beast_mode{
  text-align: end;
  padding: 0px;
  /* padding-top: 16px; */
}
.custom-background-copilot .chatBox .beast_mode{
  padding-right: 35px;
}

.custom-background-copilot .beast_mode .custom-control-label{
  color: #F3F3F3;
font-family:'DM Sans', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.custom-background-copilot .Editer_container{
  display: flex;
  gap: 35px;
  padding: 16px 43px;
  height: calc(100vh - 83px);
}

.custom-background-copilot .Editer_container .chatBox{
  width: 80%;
  margin: auto;
}
.custom-background-copilot .activeEditer_container .chatBox{
  width: 50%;
}
.custom-background-copilot .Editer_container .Computation-time{
  width: 50%;
}
.workspaces-items .workspaces-item .hover-underline-animation{
  color: var(--GRAY-TXT-STANDARD, #61636B);
font-family: 'Noto Sans',sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 18px;
padding-top: 15px;
}
.workspaces-items .workspaces-item .padding-days{
  color:  #61636B;
font-family: 'Source Sans Pro',sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px;
display: flex;
align-items: center;
justify-content: space-between;
}


.home-content .card {
  width: 400px;
  height: 180px;
}

.sticky-header-message {
  font-size: 12px;
  top: 0;
}

#scrollBar::-webkit-scrollbar-track{
	border-radius: 10px;
	background-color: transparent;
}

#scrollBar::-webkit-scrollbar{
	width: 8px;
	background-color: transparent;
}

#scrollBar::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #b3b1b1;
}


.pac-container {
  z-index: 10000 !important; /* A high z-index value to bring it to the front */
}
