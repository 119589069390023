

.offer-staffing-progress-details {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 500;
    color: #4F4F4F;
    font-size: small;
}

.offer-staffing-progress-details p {
    margin: 0 20px 0 0;
    white-space: nowrap;
}


#offer-stepper .rs-steps-item-title {
    font-size: 16px;
    color: #3e4784 !important ; 
    font-weight: 500;
}

#offer-stepper .rs-steps-item-description {
    font-size: 14px;
    color: #4F4F4F;
    font-weight: 500;
}



#offer-stepper-price-bidding .rs-input-group {
    width: 80px !important ; 
}


#offer-stepper-price-bidding button, input, optgroup, select, textarea{
    /* color: #3e4784!important ;  */

    color: #4F4F4F !important;
    font-weight: 500 !important;
}


#offer-stepper .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    background-color: #3d4784 !important ; 
    border-color: #3d4784 !important ; 
    /* color: #DB2677 !important ; */
    /* background-color: #DB2677 !important ; */
}

#offer-stepper .rs-steps-item-status-process .rs-steps-item-tail, .rs-steps-item-status-process .rs-steps-item-title:after, .rs-steps-item-tail, .rs-steps-item-title:after {
    border-color: #3d4784 ;
}



.offer-map-container {
    display: flex;
    gap: 20px; 
    align-items: flex-start; 
    justify-content: center; 
    width: 100%; 
    margin-top: 20px; 
    padding: 10px; 
    max-width: 1200px; 
}


.offer-map-container > .offer-stepper {
    flex: 1 1 60%; 
    min-width: 300px; 
}

.offer-map-container > .distance-map {
    flex: 1 1 60%; 
    min-width: 400px; 
}


@media (max-width: 768px) {
    .offer-map-container {
        flex-direction: column; 
        gap: 10px; 
    }

    .offer-map-container > * {
        flex: none; 
        width: 100%; 
    }
}
