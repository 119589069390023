.junior-analyst-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    /* background-color: #F0F4FA;  */
    background-color: #293056; 
}

/* ===== left container css */

.junior-analyst-left-container {
    min-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 10px 10px;
    /* border: 1px solid #E0E0E0; */
    
}

.handsontable-container {
    overflow-y: auto;
    border-radius: 5px; 
    margin-left: 10px; 
    overflow: hidden;
}

.handsontable-container::-webkit-scrollbar {
    width: 0px; 
}

.handsontable-container {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}


@media (min-width: 1200px) {
    .handsontable-container {
        height: 95vh; 
        width: 69vw; 
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .handsontable-container {
        height: 75vh; 
        width: 85vw; 
    }
}

@media (max-width: 767px) {
    .handsontable-container {
        height: 60vh; 
        width: 95vw; 
    }
}

.handsontable-bottom-options {
    color: #FF69B4 ; 
}


.junior-analyst-msgBox {
    background: #FAFAFA;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    padding: 13px 16px;
    /* padding: 13px 20px; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.junior-analyst-containt {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

/* ==== right side container and conversation box *=========== */

.junior-analyst-right-container {

    flex: 1;
    overflow-y: auto;
    padding: 10px;
    /* border: 1px solid #E0E0E0; */
    min-width: 30%;
    display: flex;
    flex-direction: column;

}

/*
.junior-analyst-right-container::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
}


.junior-analyst-right-container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

.junior-analyst-right-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #FFC0CB;
}
*/



.junior-analyst-chatBox {
    flex: 1;
    margin-top: 10px;
    overflow-y: auto;
    scrollbar-width: none; 
    -ms-overflow-style: none;  
}

.junior-analyst-chatBox::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

/*
.junior-analyst-chatBox {
    flex: 1;
    margin-top: 0px;
    overflow-y: auto;
    scrollbar-width: thin; 
    scrollbar-color: #fae3e3 #2b2e4a; 
}

.junior-analyst-chatBox::-webkit-scrollbar {
    width: 5px; 
    background: #2b2e4a; 
}

.junior-analyst-chatBox::-webkit-scrollbar-thumb {
    background-color: #fae3e3; 
    border-radius: 10px; 
    border: 2px solid #2b2e4a; 
}

*/


.junior-analyst-chatBox .junior-analyst-msg {
    border: 1px solid #E0E0E0;
    padding: 14px 14px 16px 16px;
    border-radius: 16px;
    display: flex;
    align-items: start;
    margin-bottom: 16px;
    background: #fbf5f5;
}

/* Other existing styles */
.junior-analyst-chatData {
    padding-right: 10px;
    height: calc(100vh - 245px) !important;
    display: flex;
    flex-direction: column;
    min-width: 70% !important;
}


/* === add show on spreadsheet button ===== */ 

.junior-analyst-chatBox .junior-analyst-msgadd {
    background: #FFE4E1;
    border-color: #FFE4E1;
    width: 80%;
    margin-left: auto;
    position: relative; /* Enable absolute positioning inside the div */
    padding: 10px; /* Padding to ensure content does not overlap the button */
}

.junior-analyst-chatBox .junior-analyst-msgadd .show-on-spreadsheet {
    position: absolute; 
    bottom: -10px; 
    right: 10px; 
    padding: 5px 10px;
    background-color: #FF69B4;
    color: white;
    border: none;
    cursor: pointer;
    /* border-radius: 50%;  */
    border-radius: 5px;  
    width: 150px; 
    height: 18px; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}


/* change the styles later if unhappy with output style of asistant  */

.junior-analyst-chatBox .junior-analyst-msgin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
}


.junior-analyst-chatBox .junior-analyst-msgin .content {
    flex-grow: 1;
}

.junior-analyst-chatBox .junior-analyst-msgin .show-on-spreadsheet {
    position: absolute;
    bottom: -10px; /* Adjust this value to control how much the button overlaps */
    padding: 5px 10px;
    background-color: #FF69B4;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    /* width: 150px; */
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.junior-analyst-chatBox .junior-analyst-msgin .button-one {
    position: absolute;
    /* left: 7%;  */
    transform: translateX(-50%);
}

.junior-analyst-chatBox .junior-analyst-msgin .button-two {
    position: absolute;
    /* left: 17%;  */
    transform: translateX(-50%);
}



/* ====== */ 

.junior-analyst-chatbot-img-persona {
    width: 24px;
    margin-right: 12px;
}

.junior-analyst-chatBox .junior-analyst-msg p img {
    max-width: 70%;
    height: auto;
    margin-right: 12px;
    display: block;
    margin-bottom: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.junior-analyst-chatBox .junior-analyst-msg p {
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.junior-analyst-chatBox .junior-analyst-msg p > ol {
    margin-bottom: 0rem;
}

.junior-analyst-chatBox .junior-analyst-msg p > table {
    font-size: .875em;
    margin-top: 2em;
    margin-bottom: 2em;
    table-layout: auto;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0px 0px;
}

.junior-analyst-chatBox .junior-analyst-msg p > table th {
    font-weight: bold;
    text-align: left;
    vertical-align: inherit;
    display: table-cell;
    background-color: #FFB6C1;
}

.junior-analyst-chatBox .junior-analyst-msg p > table tbody {
    background-color: #FAFAFA;
}

.junior-analyst-chatBox .junior-analyst-msg p > table td,
.junior-analyst-chatBox .junior-analyst-msg p > table th {
    padding: 10px 15px;
    border: 0.5px solid #E0E0E0;
}

.junior-analyst-chatBox .junior-analyst-msg p > h1,
.junior-analyst-chatBox .junior-analyst-msg p > h2,
.junior-analyst-chatBox .junior-analyst-msg p > h3,
.junior-analyst-chatBox .junior-analyst-msg p > h4,
.junior-analyst-chatBox .junior-analyst-msg p > h5,
.junior-analyst-chatBox .junior-analyst-msg p > h6 {
    font-weight: bold;
    text-decoration: underline;
    margin: 1.25em 0;
}

.junior-analyst-chatBox .junior-analyst-msg p > h1 { font-size: 14px; }
.junior-analyst-chatBox .junior-analyst-msg p > h2 { font-size: 13.5px; }
.junior-analyst-chatBox .junior-analyst-msg p > h3 { font-size: 13px; }
.junior-analyst-chatBox .junior-analyst-msg p > h4 { font-size: 12.5px; }
.junior-analyst-chatBox .junior-analyst-msg p > h5,
.junior-analyst-chatBox .junior-analyst-msg p > h6 { font-size: 12px; }

.junior-analyst-chatBox .junior-analyst-msg p {
    margin: 1em 0;
    font-size: 12px;
    line-height: 1.5;
}

.junior-analyst-chatBox .junior-analyst-msg p > code {
    display: block;
    background-color: #FFC0CB;
    color: white;
    padding: 8px;
    margin: 1em 0;
    border-radius: 4px;
    font-family: 'Courier New', monospace;
    font-size: 12px;
}

.junior-analyst-chatBox .junior-analyst-msg p > pre {
    position: relative;
    max-width: 90%;
    overflow-x: auto;
    margin: 10px 0;
    border-radius: 8px;
    background-color: #FFC0CB;
    padding-top: 15px;
}

.junior-analyst-chatBox .junior-analyst-msg p > pre > code {
    display: block;
    color: white;
    padding: 10px 15px;
    white-space: pre-wrap;
    line-height: 1.5;
}

.junior-analyst-chatBox .junior-analyst-msg p > pre::before {
    content: attr(data-lang);
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 105, 180, 0.7);
    color: #FFFFFF;
    border-radius: 3px;
    padding: 2px 6px;
    font-size: 0.8em;
    font-family: sans-serif;
    z-index: 1;
    font-weight: 550;
}

.junior-analyst-chatBox .junior-analyst-msg p > code.inline {
    display: inline;
    background-color: #FFB6C1;
    padding: 2px 4px;
    border-radius: 2px;
    font-family: 'Courier New', monospace;
    font-size: 12px;
}

.junior-analyst-chatBox .junior-analyst-msg p > ol,
.junior-analyst-chatBox .junior-analyst-msg p > ul {
    margin: 1em 0;
    padding-left: 1.25em;
    font-size: 12px;
}

.junior-analyst-chatBox .junior-analyst-msg p > ul > li {
    list-style-type: disc;
}

.junior-analyst-chatBox .junior-analyst-msg p > ol > li {
    list-style-type: decimal;
}

.junior-analyst-containt input,
.junior-analyst-containt select option,
.junior-analyst-containt textarea {
    width: 100%;
    height: 32px;
    background: transparent;
    border: none;
    outline: none;
    padding-left: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #4F4F4F;
}

.junior-analyst-containt textarea {
    scrollbar-width: none;
}

.junior-analyst-sendBtn {
    padding: 13px;
    border-radius: 16px;
    margin-left: 19px;
    border: none !important;
}

.junior-analyst-sendBtn:hover {
    background: #FFB6C1;
}

.junior-analyst-containt {
    position: relative;
}


.junior-analyst-ReactFlagsSelect-module_selectFlag__2q5gC {
    font-size: 45px !important;
}

.junior-analyst-flag-select ul {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

.junior-analyst-flag-select ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-width: 200px;
}

.junior-analyst-flag-select ul::-webkit-scrollbar {
    display: none;
}

.junior-analyst-prompt-suggestion-grow {
    top: 43%;
    flex-grow: 1;
    position: relative;
}

.junior-analyst-prompt-suggestion-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 50%;
    padding: 0.20rem;
}

.junior-analyst-prompt-suggestion-grid {
    display: grid;
    width: 80%;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    gap: 0.50rem;
    justify-content: space-around;
    margin: 0 auto;
}

.junior-analyst-prompt-suggestion-item {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.junior-analyst-prompt-suggestion-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-width: 0.5px;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: left;
    width: 100%;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, .02);
    white-space: nowrap;
    background-color: #FFC0CB;
    border-color: #FFB6C1;
    color: #FF69B4;
}

.junior-analyst-prompt-suggestion-content {
    font-family: 'Arial', sans-serif;
    display: flex;
    width: 100%;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.junior-analyst-prompt-suggestion-text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.junior-analyst-prompt-suggestion-title {
    font-weight: 550;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.junior-analyst-prompt-suggestion-subtitle {
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
}

.junior-analyst-prompt-suggestion-icon {
    position: absolute;
    bottom: 0;
    right: -1px;
    top: 0;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0));
    padding-left: 1.5rem;
    padding-right: 0.75rem;
    color: rgba(64, 65, 79, 1);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.junior-analyst-prompt-suggestion-btn:hover .junior-analyst-prompt-suggestion-icon {
    opacity: 1;
}

.junior-analyst-prompt-library-link-color {
    font-size: 12px;
    font-weight: 600;
}

.junior-analyst-prompt-library-link-color > a {
    color: #FF69B4!important;
    text-decoration: underline;
}

.junior-analyst-prompt-library-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 15px;
    max-width: 1000px;
    overflow-y: auto;
    max-height: 75vh;
}

.junior-analyst-prompt-library-container::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
}

.junior-analyst-prompt-library-container::-webkit-scrollbar-thumb {
    background-color: #FF69B4;
    border-radius: 4px;
}

.junior-analyst-prompt-library-container::-webkit-scrollbar-track {
    background-color: #FFC0CB;
    border-radius: 4px;
}

.junior-analyst-prompt-library-container {
    scrollbar-width: thin;
    scrollbar-color: #FF69B4 #FFC0CB;
}

.junior-analyst-prompt-library-card {
    background-color: #FFFFFF;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.2s;
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.junior-analyst-prompt-library-card img {
    opacity: 0;
    transition: opacity 0.3s;
    width: 20px;
}

.junior-analyst-prompt-library-card:hover img {
    opacity: 1;
}

.junior-analyst-prompt-library-card:hover {
    transform: translateY(-5px);
    background-color: #FFB6C1;
}

.junior-analyst-prompt-library-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 8px;
    color: #FF69B4;
    border-bottom: 2px solid #FFC0CB;
    padding-bottom: 8px;
}

.junior-analyst-prompt-library-subtitle {
    font-size: 14px;
    margin: 0;
    margin-top: 8px;
    color: #FF69B4;
    cursor: default;
}

.junior-analyst-prompt-library-card .fa-copy {
    visibility: hidden;
    cursor: pointer;
    transition: visibility 0.3s ease;
    margin-top: 10px;
}

.junior-analyst-prompt-library-card:hover .fa-copy {
    visibility: visible;
}

.junior-analyst-prompt-library-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.junior-analyst-prompt-library-imgCenterNav {
    height: 40px;
}

.junior-analyst-prompt-library-navbar-title {
    font-size: 32px;
    color: #FF69B4;
}

.junior-analyst-prompt-library-content {
    display: flex;
    justify-content: center;
    width: 100%;
}

/* New CSS to ensure scrolling in both containers */
.junior-analyst-left-container {
    height: 100vh;
    /* overflow-y: auto; */
}

/*
.junior-analyst-right-container {
    height: 100vh;
    overflow-y: hidden;
}
*/