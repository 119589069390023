@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.unassigne_content {
    font-family: Inter;
}

.unassigne_content.grid_View {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 23px;
}

.offer-staffing-street-view-container {
    background: #fff;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
    overflow: hidden;
    padding: 0 !important;
    box-sizing: border-box;
    width: 100% !important;
}
.offer-staffing-street-view-container .offer-staffing_img{
    position: relative;
    height: 216px;
}
.offer-staffing-street-view-container .offer-staffing_img .offer-googlestreetview{
    height: 100%;
}
.offer-staffing-street-view-container .share_icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 4px 32px 0px #0000000D;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 9;
}

.staff-order-header {
    font-weight: bold;
    margin-bottom: 10px;
}

.staff-order-name {
    font-size: 1.2em;
}

.staff-order-id {
    font-size: 0.9em;
    color: #888;
}

.staff-order-address,
.staff-order-total {
    margin: 5px 0;
}

hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 10px 0;
}

/* new */
.job-details {
    display: flex;
    align-items: center;
    gap: 4.5px;
}

.staff-required-circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    /* background-color: #db2777;  */
    background-color: #3e4784;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 1em;  */
    /* font-weight: bold;  */
}


.staff-order-body {
    padding: 20px;
}

.staff-order-body .staff-order-name {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
    color: black;
}

.staff-order-body .staff-order-address {
    font-size: 14px;
    font-weight: 400;
    color: #878787;
}

.types_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    margin-bottom: 16px;
}

.types_list .type .title {
    font-size: 12px;
    font-weight: 400;
    color: #878787;
    margin-bottom: 2px;
}

.types_list .type .value {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
}
.badge_tab > span{
    font-size: 12px;
    font-weight: 500;
    color:black;
    border-radius: 8px;
    background: #F2F2F2;
    padding: 8px;
}
.border_hr{
    border-color: #E4E4E4;
    margin: 16px auto;
}