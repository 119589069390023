#listing-billboard .map-billboard-list {
    /* height: auto;
    width: 40%;
    padding-left: 20px;
    padding-bottom: 20px; */
}



#listing-billboard-detail .view-listing-details-block .map-billboard-list {
    width: 20%;
}

#listing-billboard .mapboxgl-map {
    position: sticky;
    top: 0;
    /* width: 560px; */
    /* height: calc(100vh - 250px) !important; */
    height: 75vh !important;
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #E4E4E4;
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.05);
}

#listing-billboard-detail .mapboxgl-map {
    width: 36.9%;
    /* height: 90vh !important; */
    border-radius: 8px;
    border: none;
    margin-left: 25px;
    margin-top: 10px;
    
}


@media(max-width:992px) {
    #listing-billboard .view-listing-details-block .map-billboard-list {
        width: 100%;
        /* height: 84vh !important; */
    }

    #listing-billboard .map-billboard-list iframe {
        height: 100%;
    }

    #listing-billboard-detail .view-listing-details-block .map-billboard-list {
        width: 100%;
        /* height: 84vh !important; */
    }

    #listing-billboard-detail .map-billboard-list iframe {
        /* height: 100%; */
    }
}

/* ====== google map ========  */



.gm-ui-hover-effect{
    top: -10px !important;
    right: 15px !important;

    width: 25px !important;
    height: 25px !important;
    opacity: 1;}

.gm-ui-hover-effect img{
    margin: -8px 6px !important;
    width: 10px !important;
    height: 10px !important;
}

.gm-style .gm-style-iw {
    width: 200px !important;
    left: 0px !important;
    padding-left: 10px;
}