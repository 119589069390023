.shift-checkins-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.shift-checkins-tabs {
    display: flex;
    overflow-x: auto;
    /* margin-bottom: 16px; */
}

.shift-checkins-tab {
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #e0e0e0;
    border-bottom: none;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
    font-size: 0.9rem;
    white-space: nowrap;
}

.shift-checkins-tab-active {
    background-color: #ffffff;
    font-weight: bold;
}

.shift-checkins-tab:hover {
    background-color: #e0e0e0;
}

.shift-checkins-details-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding: 10px;
    background-color: #ffffff;
}

.shift-checkins-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.shift-checkins-date {
    font-size: 1rem;
    font-weight: 500;
}

.shift-checkins-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shift-checkins-time-location {
    display: flex;
    align-items: center;
}

.shift-checkins-icon-text {
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.shift-checkins-icon-text svg {
    margin-right: 6px;
}

.shift-checkins-meeting-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.shift-checkins-check-in, .shift-checkins-check-out {
    margin-top: 6px;
    cursor: pointer;
}

.shift-checkins-check-button {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #DB2677;
    padding: 4px 8px;
    border-radius: 4px;
    border: none;
    font-size: 0.8rem;
    transition: background-color 0.3s;
}

.shift-checkins-check-button:hover {
    background-color: #a61d59;
}

.shift-checkins-check-button svg {
    margin-right: 6px;
}

.shift-checkins-status {
    color: #ff5722;
    margin-top: 6px;
    font-size: 0.8rem;
}

.shift-checkins-countdown-ticker {
    margin-top: 6px;
}

.shift-checkins-time-left {
    color: #c62828;
    font-weight: 500;
}
