@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.header_wrapper{
    background: white;
    padding: 24px;
    font-family: Inter;
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_wrapper .leftside{
    display: flex;
    align-items: center;
    gap: 24px;
}
.header_wrapper .leftside .menubar{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5;
  border: 1px solid #E4E4E4;
  border-radius: 8px;
  cursor: pointer;
}
.header_wrapper .leftside .menubar img{
  transition: all 0.3s;
}
.header_wrapper .leftside .menubar.active img{
  transform: rotate(-180deg);
  transition: all 0.3s;
}
.header_wrapper .leftside .page_name{
    font-size: 14px;
    font-weight: 400;
    color: black
}

.header_wrapper .rightside{
    display: flex;
    align-items: center;
    gap: 16px;
}
.header_wrapper .rightside .searchBox{
    display: flex;
    padding: 10px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    
}
.header_wrapper .rightside .searchBox input{
    font-size: 14px;
    font-weight: 400;
    color: #A6A6A6;
    border: none;
    box-shadow: none;
    outline: none;
}
.header_wrapper .rightside .notification{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F5F5F5;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    padding: 15px;
}
.header_wrapper .rightside .line{
    width: 1px;
    height: 8px;
    background: #E4E4E4;
    display: inline-block;
}

  .header_wrapper .rightside .rs-dropdown,
  .header_wrapper .rightside .rs-dropdown-toggle,
  .header_wrapper .rightside .profile {
    width: 100% !important;
  }

  .header_wrapper .rightside .rs-dropdown-toggle svg{
    fill: #202020;
    display: none;
  }
  .header_wrapper .rightside .rs-dropdown-toggle{
    padding: 0;
    align-items: center;
    background: transparent !important;
  }

  .header_wrapper .rightside .profile{
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .header_wrapper .rightside .profile > a{
    height: 40px;
  }
  .header_wrapper .rightside .profile .user_details .name{
    font-weight: 600;
    margin: 0;
    color: #000000;
  }
  .header_wrapper .rightside .profile .user_details .roll{
    font-size: 12px;
    font-weight: 400;
    color: #878787;
    display: block;
    text-align: left;
  }
  .header_wrapper .rightside .rs-dropdown-toggle .rs-icon{
    top: 10px !important;
    right: 0 !important;
  }