@import "rsuite/dist/rsuite.css"; 
/* @import "/src/components/collaboration/rsuite-default-custom.css"; */

#listing-billboard .listing-sidebar-section {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 20px;
    height: auto;
    /* min-height: 844px; */
}


#listing-billboard .listing-section .sideBar::-webkit-scrollbar {
    display: none ; 
}

#listing-billboard .sidebar-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

#listing-billboard .sidebar-items .sidebar-item {
    padding: 24px 0;
    border-bottom: 1px solid #E0E0E0;
}

#listing-billboard .sidebar-items .sidebar-item:last-child {
    border: none;
}

#listing-billboard .sidebar-items .sidebar-item:first-child {
    padding-top: 0;
}

#listing-billboard .sidebar-items .sidebar-item .category_title {
    margin: 0 0 16px;
}

#listing-billboard .sidebar-items .sidebar-item .item-title h2 {
    font-weight: 700;
    font-size: 14px;
    /* color: #333333; */
    color: #3e4784;
    margin: 0;
    line-height: normal;
}

#listing-billboard .sidebar-items .sidebar-item .item-title .availability {
    margin-bottom: 12px;
}

#listing-billboard .sidebar-items .sidebar-item .item-title {
    margin-bottom: 12px;
}

#listing-billboard .sidebar-item .react-datepicker-wrapper input {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 16px 10px 39px;
    width: 100%;
    outline: none;
}

#listing-billboard .availability-datePicker {
    position: relative;
}

#listing-billboard .availability-datePicker .availability-datePicker-icon svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    cursor: pointer;
    pointer-events: none;
    /* z-index: 10; */
}

#listing-billboard .availability-datePicker .datepicker-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
    pointer-events: none;
    cursor: pointer;
}

#listing-billboard .availability-datePicker .datepicker-arrow svg {
    cursor: pointer;
}

#listing-billboard .sidebar-items .sidebar-item .range,
.sidebar-items .sidebar-item .impressions {
    font-weight: 600;
    font-size: 12px;
    color: #828282;
    margin: 6px 0 25px;
    line-height: 15px;
}

.rs-slider-bar {
    background: #E0E0E0;
    border-radius: 2px;
}

.rs-slider-progress-bar {
    /* background-color: #3e4784 !important; */
    /* background-color: #ffb2dc !important; */
    background-color: #898fbe!important;

}

#listing-billboard .rs-slider-handle::before {
    width: 16px;
    height: 16px;
    /* background: #db2777; */
    background: #3e4784;
    border: 2px solid #FFFFFF;
    top: -2px;
}

#listing-billboard .sidebar-items .sidebar-item label {
    display: block;
    margin-bottom: 14px;
}

#listing-billboard .sidebar-items .sidebar-item label .MuiCheckbox-root {
    padding-top: 0;
    padding-bottom: 0;
}

#listing-billboard .sidebar-items .sidebar-item label .MuiCheckbox-root svg path {
    fill: #828282;
}

#listing-billboard .sidebar-items .sidebar-item label .Mui-checked svg path {
    fill: #db2777;
}

#listing-billboard .sidebar-items .sidebar-item label .MuiFormControlLabel-label {
    font-weight: 500;
    font-size: 12px;
    color: #4F4F4F;
    font-family: inherit ; 
}

#listing-billboard .sidebar-items .sidebar-item .see_all_Btn {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    color: #828282;
    cursor: pointer;
    border-bottom: 1px solid #828282;
}

#listing-billboard .listing-sidebar-section .filter_btn button {
    background: #db2777;
    border-radius: 8px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
}

#listing-billboard .listing-sidebar-section .filter_btn button svg {
    margin-right: 10px;
}

#listing-billboard .filter_dialog #alert-dialog-title {
    border-bottom: 1px solid #eeedf3;
    padding: 24px;
}

#listing-billboard .filter_dialog .MuiPaper-rounded {
    width: 100%;
    max-width: 800px;
}

#listing-billboard .filter_dialog #alert-dialog-title h2 {
    font-size: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    line-height: normal;
    font-weight: 600;
    position: relative;
}

#listing-billboard .filter_dialog #alert-dialog-title h2 svg {
    position: relative;
    top: 0;
    right: 0;
    font-size: 28px;
    cursor: pointer;
}

#listing-billboard .filter_dialog #alert-dialog-title p {
    font-size: 14px;
    color: #898e9f;
}

#listing-billboard .MuiDialogContent-root {
    padding: 24px 20px 24px 40px !important;
}

#listing-billboard .MuiDialogContent-root .filter_items h2 {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 10px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type {
    display: flex;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .MuiAccordion-root {
    border: 1px solid #dcdfe5;
    margin-bottom: 15px;
    box-shadow: none;
    width: calc(100% - 50px);
    border-radius: 5px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .MuiAccordionSummary-root {
    background: #f3f4fa;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .MuiAccordionSummary-root .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .MuiAccordionSummary-root .title {
    display: flex;
    align-items: center;
    color: #141e44;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .MuiAccordionSummary-root .title svg {
    margin-right: 10px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .css-15v22id-MuiAccordionDetails-root {
    padding: 16px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .tab {
    border: solid #d7d9e2;
    border-width: 1px 0 1px 1px;
    padding: 10px;
    flex: 1;
    text-align: center;
    color: #8e91a4;

}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .active {
    /* background: #4274de; */
    background: #3e4784;
    color: white;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .tab:last-child {
    border-right: 1px solid #d7d9e2;
    border-radius: 0 5px 5px 0;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .tab:first-child {
    border-radius: 5px 0 0 5px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid #d7d9e2;
    width: 35%;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .rating .star {
    color: #c6cada;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .rating .active {
    color: #fac385 !important;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .and_or .btn_and {
    background: #d7efdd;
    color: #3da45c;
    margin-right: 10px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .and_or .btn_or {
    color: #3e4784;
    background: #d8e3fe;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .date_content {
    display: flex;
    align-items: center;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .filter_date {
    width: 50%;
    padding: 5px;
    border: 1px solid #e0e1e7;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .filter_date:first-child {
    margin-right: 10px;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .filter_date input {
    width: 100%;
    border: none;
    outline: none;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .filter_date svg {
    color: #c9ccdc;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .delete_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f4f8;
    margin-left: 10px;
    cursor: pointer;
}

#listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .delete_icon svg {
    color: #cacfde;
}

#listing-billboard .filter_dialog .footer {
    padding: 15px 30px;
    justify-content: space-between;
    background: #f9fafe;
    border-top: 1px solid #edecf1;
}

#listing-billboard .filter_dialog .footer .btn {
    padding: 10px 25px;
    text-transform: capitalize;
}

#listing-billboard .filter_dialog .footer .btn_cancel {
    background: #dce1ec;
    color: #414558;
}

#listing-billboard .filter_dialog .footer .btn_apply {
    background: #41b664;
    color: white;
}

#listing-billboard .filter_dialog .footer .btn_apply svg {
    margin-right: 5px;
    font-size: 18px;
}

#listing-billboard .rs-slider-bar {
    color: #3e4784!important;
    
}



@media (max-width: 575px) {
    #listing-billboard .MuiDialogContent-root {
        padding: 24px !important;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .tab {
        display: block;
        flex: none;
        width: 100%;
        border-width: 1px 1px 0 1px;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .tab:first-child {
        border-radius: 5px 5px 0 0;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .tab:last-child {
        border-radius: 0 0 5px 5px;
    }

    .MuiDialogContent-root .filter_items .content .filter_type .arriving_date .tab:last-child {
        border-bottom: 1px solid #d7d9e2;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .date_content {
        flex-wrap: wrap;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .date_content .filter_date:first-child {
        margin: 0 0 10px 0;
    }

    .MuiDialogContent-root .filter_items .content .filter_type .filter_date {
        width: 100%;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .rating {
        width: 100%;
    }

    #listing-billboard .filter_dialog .footer {
        padding: 15px;
    }

    #listing-billboard .filter_dialog .footer .btn {
        padding: 6px 10px;
    }

    #listing-billboard .filter_dialog #alert-dialog-title {
        padding: 16px;
    }
}

@media (max-width: 375px) {
    #listing-billboard .MuiDialogContent-root {
        padding: 15px !important;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .delete_icon {
        width: 30px;
        height: 30px;
    }

    #listing-billboard .MuiDialogContent-root .filter_items .content .filter_type .delete_icon svg {
        font-size: 20px;
    }

    #listing-billboard .filter_dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        margin: 32px 15px;
    }
}