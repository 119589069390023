.rejection-modal {
    width: 40%;
    height: 350px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    font-family: inherit;
}

.rejection-modal-container {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
}

.rejection-modal-content {
    /* text-align: center; */
    width: 100%;
}

.rejection-modal-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.rejection-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rejection-modal-form-check {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.rejection-modal-form-check-input {
    margin-right: 10px;
}

.rejection-modal-form-check-label {
    font-size: 1rem;
}

.rejection-modal-btn {
    width: 100%;
    max-width: 150px;
    padding: 8px;
    font-size: 1rem;
    background-color: #DB2677;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    font-weight: 500;
}


.rejection-modal-error {
    color: red;
    margin-bottom: 10px;
}
