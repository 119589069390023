@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.unassigne_content{
    font-family: Inter;
}
.unassigne_content .table{
    border-collapse: separate;
    border-spacing: 0 16px;
}
.unassigne_content .table thead tr th{
    font-size: 14px;
    font-weight: 400;
    color: #545454;
    border: none;
    padding: 0 12px;
}

.unassigne_content .table tbody tr {
    background: white;
}
.unassigne_content .table tbody tr td{
    padding: 12px !important;
    border: none;
}
.unassigne_content .table tbody tr td:first-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.unassigne_content .table tbody tr td:last-child{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.unassigne_content .table tbody tr td{
    vertical-align: middle;
}
.unassigne_content .table tbody tr td .user_details{
    display: flex;
    align-items: center;
    gap: 8px;
}
.unassigne_content .table tbody tr td .user_details .profile img {
    width: 56px;
    height: 56px;
    /* 
    border-radius: 8px;
    background: #D9D9D9;
    overflow: hidden; */
}
.unassigne_content .table tbody tr td .user_details .details .name{
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 2px;
}
.unassigne_content .table tbody tr td .user_details .details .address{
    font-size: 14px;
    font-weight: 400;
    color: #878787;
    margin: 0;
}
.unassigne_content .table tbody tr td .required_col,
.unassigne_content .table tbody tr td .start_col,
.unassigne_content .table tbody tr td .rate_col{
    font-weight: 500;
    margin: 0;
}
.unassigne_content .table tbody tr td .share_icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 4px 32px 0px #0000000D;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}
.unassigne_content .table tbody tr td.badge_tab > span{
    font-size: 12px;
    font-weight: 500;
    color:black;
    border-radius: 8px;
    background: #F2F2F2;
    padding: 8px;
}


.rate_col .rs-progress-circle-info {
    font-size: 10px; 
    height: 40px; 
    line-height: 40px;
    text-align: center; 
    color: #8e8e93; 
}
