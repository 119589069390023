@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.sidebar {
  width: 272px;
  background-color: #fff;
  height: 100vh; /* Adjust height as necessary */
  box-shadow: 2px 0 5px rgba(31, 10, 10, 0.1);
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  border-right: 1px solid #E4E4E4;
  transition: all 0.3s;
}
.sidebar.active {
  width: 89px;
  transition: all 0.3s;
  background-color: #232639;
}
.sidebar.active .sidebar-header .rs-dropdown-toggle {
  padding: 0;
  transition: all 0.3s;
}
.sidebar.active .sidebar-header .rs-dropdown-toggle svg,
.sidebar.active .sidebar-header .profile .user_details,
.sidebar.active .navbar_wrapper .navbar_inner .title,
.sidebar.active .navbar_wrapper .navbar_inner .sidebar-nav a span{
  display: none;
  transition: all 0.3s;
}
.sidebar.active .navbar_wrapper .navbar_inner .sidebar-nav a{
  padding: 10px;
  transition: all 0.3s;
}

.sidebar-header {
  text-align: center;
  }
  
  .sidebar-header .rs-dropdown{
    padding: 24px;
    border-bottom: 1px solid #E4E4E4;
  }

  .sidebar-header .rs-dropdown,
  .sidebar-header .rs-dropdown-toggle,
  .sidebar-header .profile {
    width: 100% !important;
  }
  .sidebar-header .rs-dropdown-toggle svg{
    fill: #202020;
  }
  .sidebar-header .rs-dropdown-toggle{
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    align-items: center;
    background: transparent !important;
    transition: all 0.3s;
  }

  .sidebar-header .profile{
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .sidebar-header .profile .user_details{
    transition: all 0.3s;
  }
  .sidebar-header .profile .user_details .name{
    font-weight: 600;
    margin: 0;
    color: #000000;
  }
  .sidebar-header .profile .user_details .roll{
    font-size: 12px;
    font-weight: 400;
    color: #878787;
    display: block;
    text-align: left;
  }
  .sidebar-header .rs-dropdown-toggle .rs-icon{
    top: 10px !important;
    right: 0 !important;
  }

  .sidebar-header .rs-dropdown .rs-dropdown-menu{
    width: 85%;
    left: 50% !important;
    transform: translate(-50%, 0px);
  }

  .sidebar-nav {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .navbar_wrapper{
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 94px);
    overflow-y: auto;
  }
  
  .navbar_wrapper .navbar_inner:first-child{
    margin-bottom: 24px;
  }
  .navbar_wrapper .navbar_inner .title{
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #878787;
    transition: all 0.3s;
  }

  .sidebar-nav a {
    color: #68686A;
    padding: 12px;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 8px;
    font-weight: 500;
  }
  
  
  .sidebar-nav a span{
    transition: all 0.3s;
  }
  /* .sidebar-nav a:hover, */
  .sidebar-nav a.active {
    background-color: #F4F4F6;
    color: #3e4784;
  }

  .sidebar-nav a:hover{
    background-color: #ffeff5;
    color: #5c67ac;
  }


  .sidebar-nav a:hover svg path,
  .sidebar-nav a.active svg path{
    stroke: #3e4784;
  }
  .sidebar-nav a.logout:hover svg path,
  .sidebar-nav a.logout.active svg path{
    stroke: #FF5C57;
  }
  .sidebar-nav a.logout {
    color: #FF5C57;
  }
  
  .sidebar-footer {
    text-align: center;
    color: #464646 !important;
  }

  
  .dashboard-sidebar-avatar-link {
    transition: transform 0.3s ease;
  }
  
  .dashboard-sidebar-avatar-link:hover {
    transform: scale(1.1);
  }
  
  .dashboard-sidebar-logout-span {
    transition: color 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    align-items: center;
  }
  
  .dashboard-sidebar-logout-span:hover {
    color: #FF5733; 
    transform: translateY(-3px);
  }
  
  