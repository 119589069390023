.rules-engine-dispute-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
}

.rules-engine-dispute-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.rules-engine-dispute-header-row {
    background-color: #f7f7f7;
}

.rules-engine-dispute-header-cell {
    padding: 6px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.rules-engine-dispute-body {
    width: 100%;
}

.rules-engine-dispute-row {
    border-bottom: 1px solid #ddd;
}

.rules-engine-dispute-cell {
    padding: 6px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    text-align: left;
}

.rules-engine-dispute-input,
.rules-engine-dispute-select {
    width: 100%;
    padding: 4px 8px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #000000;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.rules-engine-dispute-input[readonly],
.rules-engine-dispute-select[disabled] {
    background-color: #f3f3f3;
    color: #666;
}

.rules-engine-dispute-icon-button {
    color: #ff4d4f;
}

.rules-engine-dispute-add-row {
    margin-top: 10px;
}

.rules-engine-dispute-add-icon {
    color: #007bff;
    cursor: pointer;
}

.rules-engine-dispute-add-icon:hover {
    color: #0056b3;
}
