/* LandingPage.css */
.security-staff-registration-container {
    color: #3d4784;
    text-align: center;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 95vh; /* Ensure it takes the full viewport height */
  }
  
  .security-staff-registration-header {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
  }
  
  .security-staff-registration-header-content {
    max-width: 100vw;
    margin: 0 auto;
  }
  
  .security-staff-registration-title {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .security-staff-registration-subtitle {
    font-size: 1.5em;
    margin-bottom: 30px;
  }
  
  .security-staff-registration-button {
    background-color: #db2777;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
  }
  
  .security-staff-registration-features {
    display: flex;
    justify-content: space-around;
    align-items: flex-start; /* Align items to the top for balance */
    flex: 1;
    background-color: #3d4784;
    color: #ffffff;
    padding: 50px 0;
  }
  
  .security-staff-registration-feature {
    max-width: 300px;
    text-align: left;
    font-size: 1.2em;
  }
  
  .security-staff-registration-icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .calendar-icon:before {
    content: "\1F4C5"; /* Unicode for calendar icon */
  }
  
  .money-icon:before {
    content: "\1F4B0"; /* Unicode for money bag icon */
  }
  
  .ar-app-icon:before {
    content: "\1F4F1"; /* Unicode for mobile phone icon */
  }
  