@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.signal_page_wraper{
    width: 100%;
    font-family: Inter;
}
/* .staffing-new-assignments-body{
    width: calc(100% - 272px);    
}
.staffing-new-assignments-body.active_sideBar{
    width: calc(100% - 89px);
} */
.signal_page_wraper .signal_inner .tabs_content_wraper{
    padding: 0 24px;
    margin-top: 24px;
    display: flex;
    align-items: center;
}
.signal_page_wraper .signal_inner .tabs_content_wraper .tab_item{
    font-size: 16px;
    line-height: 24px;
    padding: 12px;
    color: #878787;
    font-weight: 400;
    cursor: pointer;
}
.signal_page_wraper .signal_inner .tabs_content_wraper .tab_item.active{
    border-bottom: 1px solid #404880;
    font-weight: 600;
    color: #404880;
}
.signal_page_wraper .signal_inner .tabs_inner_content{
    padding: 24px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content{
    display: grid;
    grid-template-columns: 3fr 9fr;
    gap: 24px;
    margin-bottom: 24px;    
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .map-details{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .map-details .resident-count{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 16px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .map-details .resident-count .icon{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .map-details .resident-count .icon span{
    color: #545454;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .map-details .resident-count .count{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 0;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-block{
    align-items: unset;
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 24px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-block .Livingwith-content,
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .household-content,
.signal_page_wraper .signal_inner .tabs_inner_content .Apartment-wrapper .Apartment-content,
.signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block .Household-content,
.signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block .age-content,
.signal_page_wraper .signal_inner .tabs_inner_content .races_chart .Education-wrapper .Education-content,
.signal_page_wraper .signal_inner .tabs_inner_content .Occupations-wrapper .Occupations-block,
.signal_page_wraper .signal_inner .tabs_inner_content .Races-wrapper .Races-block{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.05);
    border: none;
    margin: 0;
    min-height: unset;
    width: 100%;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-block .Livingwith-contents,
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .household-contents,
.signal_page_wraper .signal_inner .tabs_inner_content .races_chart .Education-wrapper .Education-content,
.signal_page_wraper .signal_inner .tabs_inner_content .Occupations-wrapper .Occupations-block,
.signal_page_wraper .signal_inner .tabs_inner_content .Races-wrapper .Races-block{
    padding: 24px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-block .fontstyleaxiform,
.signal_page_wraper .signal_inner .tabs_inner_content .Apartment-wrapper .Apartment-content .Apartment-title h6, 
.signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block .fontstyleaxiform,
.signal_page_wraper .signal_inner .tabs_inner_content .races_chart .Education-wrapper .Education-content .Education-title,
.signal_page_wraper .signal_inner .tabs_inner_content .Occupations-wrapper .Occupations-block .fontstyleaxiform,
.signal_page_wraper .signal_inner .tabs_inner_content .Races-wrapper .Races-block .fontstyleaxiform{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 16px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-wrapper .Living-desc{
    font-family: Inter;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-block .Living-description{
    padding-top: 0;
    justify-content: space-between;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-block .Living-content{
    margin-left: 20px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .Livingwith-block .Living-description .Living-desc-wrapper .Living-,
.Livingwith-wrapper .household-desc{
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 16px;
    font-family: Inter;
}
.signal_page_wraper .signal_inner .tabs_inner_content .grid_box_content .household-description, 
.signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block .Household-content .Household-description,
.signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block .age-content .age-description,
.signal_page_wraper .signal_inner .tabs_inner_content .races_chart .Education-wrapper .Education-content .Education-detail{
    padding-top: 0;
}
.signal_page_wraper .signal_inner .tabs_inner_content .Apartment-wrapper,
.signal_page_wraper .signal_inner .tabs_inner_content .races_chart{
    margin-bottom: 24px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .Apartment-wrapper .Apartment-content .Apartment-description{
    padding-top: 0;
    gap: 40px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .Apartment-wrapper .Apartment-content .Apartment-description .Apartment-detail{
    padding: 0;
    margin: 0;
}
.signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block{
    gap: 24px;
    align-items: unset;
    display: grid;
    grid-template-columns: 6fr 6fr;
}
.signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block .age-content{
    margin: 0;
}
.signal_page_wraper .signal_inner .tabs_inner_content .bar_chart{
    margin-bottom: 24px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .races_chart{
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 24px;
}
.signal_page_wraper .signal_inner .tabs_inner_content .Races-wrapper{
    padding: 0;
}
.select_city{
    display: flex;
    align-items: center;
    gap: 8px;
}
.select_city .rs-picker-toggle-value,
.select_city .rs-picker-toggle-placeholder{
    color: #000 !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item-focus{
    color: black !important;
}
.select_city .rs-picker-caret-icon, 
.select_city .rs-picker-{
    top: 10px !important;
}

@media (max-width: 1575px) {
    .signal_page_wraper .signal_inner .tabs_inner_content .races_chart,
    .signal_page_wraper .signal_inner .tabs_inner_content .Household-wrapper .Household-block{
        grid-template-columns: 1fr;
    }
}


/* Event Card Styling */
.signal-events-card {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.05);
    margin: 10px 0;
    padding: 20px;
  }
  
  /* Event Title */
  .signal-events-title {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    margin: 0;
  }
  
  /* Event Location */
  .signal-events-location {
    color: #404040;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin: 5px 0;
  }
  
  /* Event Date */
  .signal-events-date {
    color: #616161;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin: 5px 0;
  }
  
  /* Event Classification */
  .signal-events-classification {
    color: #9E9E9E;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    margin: 5px 0;
  }
  
  /* Event Details Section (Predicted Spend and Attendance) */
  .signal-events-details {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  
  .signal-events-spend {
    color: #CC457D;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  
  .signal-events-attendance {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  
  .signal-events-spend span,
  .signal-events-attendance span {
    font-size: 12px;
    font-weight: 400;
    color: #9E9E9E;
    display: block;
  }
  
  /* More Details Button */
  .signal-events-more-details {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  
  .signal-events-details-btn {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #CC457D;
    border: 1px solid #CC457D;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-decoration: none;
  }
  
  .signal-events-details-btn:hover {
    background-color: #CC457D;
    color: #fff;
  }
  