@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.customer_page{
  font-family: Inter;
  background: linear-gradient(180deg, #D7E1FF 0%, #EEF1FA 80.5%, #FFF 100%);
  height: calc(100vh - 89px);
  overflow: hidden;
}
.customer_page .page_title .right_side{
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
}
.customer_page .page_title .right_side .count_of_customer_btn{
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background: #FFF;
}
.customer_page .page_title .right_side .count_of_customer_btn .count_of_customer{
  color: #15B707;
  font-size: 12px;
  font-weight: 600;
  border-radius: 99px;
  background: #E8FEE6;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer_page .page_title .right_side .create_customer_btn{
  border-radius: 8px;
  background: #404880;
  padding: 12px 16px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.customer_page .inner_content{
  padding: 24px;
  display: flex;
  gap: 24px;
  align-items: flex-start;
  height: calc(100vh - 197px);
  overflow-y: auto;
}
.customer_page .inner_content .filter_content{
  padding: 16px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.05);
  width: 304px;
  height: auto;
  position: sticky;
  top: 0;
}
.customer_page .inner_content .filter_content .heading{
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;
}
.customer_page .inner_content .filter_content .heading .title{
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
}

/*
.customer_page .inner_content .filter_content .heading  .sub_title{
  color: #9E9E9E;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}
*/
.customer_page .inner_content .filter_content .heading .sub_title {

  font-size: 14px; /* Minimalistic font size */
  font-weight: 400; /* Lighter font weight */
  line-height: 20px;
  text-decoration: none; /* Remove underline for a cleaner look */
  cursor: pointer;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
  color: #374151; /* Darker gray on hover for subtle emphasis */
  text-decoration: underline; /* Underline on hover */
}


.customer_page .inner_content .filter_content .search_box{
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E4E4E4;
  background: #FFF;
  padding: 10px;
  gap: 8px;
  margin-bottom: 16px;
}
.customer_page .inner_content .filter_content .search_box input{
  color: #A6A6A6;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  background: transparent;
  outline: none;
}
.customer_page .inner_content .filter_content .folter_items{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}
.customer_page .inner_content .folter_items .rs-picker-select .rs-picker-toggle{
  border-radius: 8px;
  border: 1px solid #E4E4E4;
  background-color: #F7F8FA !important;
  padding: 10px 32px 10px 10px;
}
.customer_page .inner_content .folter_items .rs-picker-select .rs-picker-toggle .rs-picker-toggle-placeholder{
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.customer_page .inner_content .folter_items .rs-picker-select .rs-picker-toggle .rs-picker-caret-icon{
  top: 10px;
}
.customer_page .inner_content .toggle_section{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}
.customer_page .inner_content .toggle_section .toggle_lable{
  display: flex;
  align-items: center;
  gap: 8px;
}
.customer_page .inner_content .toggle_section .toggle_lable .rs-toggle{
  margin: 0 !important;
}
.customer_page .inner_content .toggle_section .toggle_lable .rs-toggle.rs-toggle-checked .rs-toggle-presentation{
  background-color: #404880 !important;
}
.customer_page .inner_content .toggle_section .toggle_lable .rs-toggle .rs-toggle-presentation{
  background-color: #B6B9CF !important;
}
.customer_page .inner_content .toggle_section .toggle_lable .lable{
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.create_customer_modal_wrapper{
  width: 750px !important;
}
.create_customer_modal_wrapper .rs-modal-content{
  padding-top: 50px;
}
.create_customer_modal_wrapper .rs-modal-content .rs-modal-header .rs-btn-close svg{
  fill: #000;
  width: 20px;
  height: 20px;
}
.customer_page .inner_content .apply_btn{
  border-radius: 8px;
  background: #404880;
  padding: 12px 16px;
  width: 100%;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.customer_page .tabs_content{
  width: calc(100% - 328px);
}
.customer_page .tabs_content .tabs{
  border-bottom: 1px solid #E4E4E4;
  display: flex;
  margin-bottom: 26px;
}
.customer_page .tabs_content .tabs ._tab{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.customer_page .tabs_content .tab_inner_content .staffing-set-up-guide-container{
  padding: 0;
}
.customer_page .tabs_content .tab_inner_content .no_data{
  background: white;
  text-align: center;
  padding: 50px;
  border-radius: 8px;
}
.create_customer_modal .order-form-container {
  margin: auto; 
  font-family : 'DM Sans', sans-serif !important
}
.configure-form .hr{
  border-color: #E4E4E4;
  opacity: 1;
}
.configure-form .phone_field{
  margin-bottom: 40px !important;
}
.modal-footer_btn{
    position: absolute;
    width: 100%;
    bottom: -40px;
    background: white;
    z-index: 99;
    text-align: right !important;
}

.order-form-container-card {
  background: #fff;
  border: none;
}


.hr-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0 40px 0; 
}

.hr-with-text hr {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc;
  /* border-top: 1px solid #FFFFFF ; */
  margin: 0;
}

.hr-with-text span {
  padding: 0 10px;
  background: #fff;
  color: #333;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  display: flex;
  align-items: center;
}

.hr-with-text span {
  width: 40px; 
  height: 40px; 
  /* background: #fff; */
  background: #EBEFFA;
  color: #333;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; 
  border-radius: 50%; 
  border: 1px solid #ccc; 
  box-sizing: border-box; 
}

.customer_inner_content{
  display: flex;
  height: calc(100vh - 89px);
  border-top: 1px solid #E4E4E4;
}

.hr-with-text span::before,
.hr-with-text span::after {
  content: "";
  flex: 1;
  border-top: 1px solid #ccc;
}

.hr-with-text span::before {
  margin-right: 10px;
}

.hr-with-text span::after {
  margin-left: 10px;
}


.selected-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .selected-location span {
    flex-grow: 1;
  }
  
  .selected-location .btn-link {
    text-decoration: none;
    color: #ff0000;
    font-size: 16px;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 3px;
    cursor: pointer;
    background: none;
    border: none;
  }

  /* Autocomplete.css */
.autocomplete-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .autocomplete-container .form-control {
    padding-right: 40px; /* Adjust this padding as needed */
  }
  
  .location-icon {
    position: absolute;
    right: 10px;
    color: #ccc;
  }
  
  
  /* shift date time picker */

  .staffing-shifttimer-date-time-picker {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
 
  }
  
  .staffing-shifttimer-shift {
    /* display: flex;
    align-items: center;
    gap: 10px; */

    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    cursor: pointer;
  }
  
  .staffing-shifttimer-btn-link {
    text-decoration: none;
    color: #007bff;
    font-size: 14px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }
  
  .staffing-shifttimer-btn-link:hover {
    color: #0056b3;
  }

  .PhoneInputInput{
    /* border: 1px solid #E0E0E0;
    border-radius: 8px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-bottom: 24px;
    cursor: pointer;
    position: relative; */
    display: block;
    width: 90%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }


  .staffing-btn {
    display: inline-block;
    font-family: inherit !important;
    font-weight: 500;
    font-size: 16px !important;
    text-align: center;
    padding: 5px 15px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin: 5px;
    border: none;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
  .staffing-btn.cancel-btn {
    background: #f3f3f3;
    color: #333;
  }
  
  .staffing-btn.confirm-btn {
    background: #404880 !important;
    color: #fff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }
  
  .staffing-btn:hover {
    /* background-color: #b91c6c !important; */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  }
  
  .staffing-btn:active {
    /* background-color: #9c165a !important; */
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .staffing-cancel-icon {
    font-size: 18px!important ;  
    color: #DC3546 ; 
  }
  

  .toggle-container {
    position: fixed;
    top: 10px; 
    right: 10px; 
    font-weight: bold;
    padding: 5px; 
    z-index: 1000; 
}

.toggle-container span {
    font-size: 14px;
    color: #3D4784;
    
    /* font-weight: bold; */
    margin-right: 5px;
}

.form-already-filled-in-values {
  display: block;
  width: 80%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f5f4f4;
  background-clip: padding-box;
  border: 1px solid #f9f9f9;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


/* === existing customers ==== */

.provider-company-customer-container {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Spacing between cards */
}

.provider-company-customer-card {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-color: #ddd;
}

.provider-company-customer-card-content {
  padding: 20px; /* Padding inside the card */
}

.provider-company-customer-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Spacing below the header */
}

.provider-company-customer-title {
  color: #3D4784;
  margin-left: 8px; /* Spacing between icon and title */
}

.provider-company-customer-divider {
  margin: 10px 0;
  background-color: #ddd;
}

.provider-company-customer-legal-name,
.provider-company-customer-contact-name,
.provider-company-customer-email,
.provider-company-customer-job-count {
  margin-top: 10px;
  color: #555;
}

.provider-company-customer-icon {
  font-size: 18px;
  color: #3D4784;
}

.provider-company-customer-button {
  margin-top: 15px;
  background-color: #db2777;
  color: #ffffff;
  width: auto;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: none;
}

.provider-company-customer-no-companies {
  text-align: center;
  width: 100%;
  padding: 20px;
  color: #3D4784;
}


/* == drop container ==== 
.document-drop-container {
  border: 2px dashed #13A391;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.drop-zone {
  font-size: 16px;
  color: #6c757d;
}

.drop-zone p {
  margin: 0;
}

*/
.document-drop-container {
  border: 2px dashed #13A391;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  position: relative;
}

.document-drop-container.drag-over {
  background-color: #e0f7f4; /* Background color when dragging over */
}

.drop-zone {
  font-size: 16px;
  color: #6c757d;
}

.drop-zone p {
  margin: 0;
}

.file-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.remove-button {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.remove-button:hover {
  color: #cc0000;
}



.prepopulated-values-data {
  color: #5f6368;
  font-size: 14px;
}

.prepopulated-values-data .icon {
  font-size: 16px;
  color: #38406a;
  margin-right: 8px;
}

.prepopulated-values-data .prepopulated-value {
  background-color: #F5F6FB;
  color: #38406a;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 4px;
  margin-top: 4px;
}


.prepopulated-values-data .edit-button {
  color: #5f6368; 
  background-color: #e8e8e8;
  cursor: pointer;
  vertical-align: middle;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

