
/*
.react-calendar__month-view__days__day--weekend {
    color: rgb(0, 0, 0) !important;
  }
  
  .react-calendar__tile--now {
    background: #5571D1 !important;
  }
  */
  .react-calendar button {
    border-radius: 5px;
  }
  .react-calendar__tile--active {
    background: #5571d1;
  }

  .react-calendar {
    background-color: #eaecf4;
    border: none ; 
    line-height: 1.5em;
    font-family: inherit !important;
  }
  
  .react-calendar__navigation {
    font-weight: 600;
  }
  
  .react-calendar__navigation button {
    color: #333;
    min-width: 44px;
    background: none;
    font-size: 16px;
  }
  
  .react-calendar__month-view__weekdays {
    text-transform: uppercase;
    color: #333;
    font-weight: bold;
  }
  
  .react-calendar__tile--now {
    background: #ffeded;
    border-radius: 50%;
  }
  
  .react-calendar__tile--active {
    /* background: #006edc; */
    background: #5571D1 ;
    color: white;
    border-radius: 50%;
  }
  
  .react-calendar__tile:disabled {
    /* background: #f0f0f0; */
    background: #eaecf4!important;
    color: #a0a0a0;
  }


  .scheduler-modal{
      position: absolute;
      padding: 1rem; 
      /* background-color: #FFFFFF; */
      background-color: #eaecf4;
      border-radius: 5px;
    /*
    top: 50%;
    left: 50%;
    display: block;
    min-width: 50em;
    min-height: auto;
    font-family: Axiform;
    transform: translate(-50%, -50%);
    */
      /* outline: transparent; */
      top: 50% ; 
      left: 50% ; 
      right: auto ; 
      bottom: auto ; 
      margin-right: -50% ; 
      transform: translate(-50%, -50%) ; 
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) ; 
      width: 800px ;
    
  }
  
  .scheduler-modal-overlay {
    z-index: 99;
    position: fixed; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(126, 121, 121, 0.4); 
    /* backdrop-filter: blur(2px);  */
    /* background-color: rgba(30, 30, 30, 0.2); */
  }


  .time-slot {
    border-radius: 8px; 
    border: 1px solid #dcdcdc; /* Light grey border, adjust as necessary */
    font-size: 14px; /* Adjust the font size as necessary */
    color: #5d627c ;   
    font-weight: 600;  
    margin: 0; /* Adjust margin as necessary */
    cursor: pointer; /* If this is a button or clickable element */
    user-select: none; /* Optional: Prevents the text from being selectable */
  }


  
  .react-calendar__tile--highlight {
    background: #a0d6b4;
    color: white;
}

 
  