

/* === new  */

.offer-staffing-app-container {
    display: flex;
    gap: 20px; 
    /* padding: 20px; */
    background : linear-gradient(180deg, #D7E1FF 0%, #EEF1FA 80.5%, #FFF 100%)!important;
}

.offer-staffing-progress-container {
    /* display: flex; */
    color: #333;
    padding: 20px;
    border-radius: 8px;
}

.offer-staffing-progress-title {
    font-size: 24px;
    font-weight: bold;
    color: #3e4784;
    margin-bottom: 5px;
}


.offer-staffing-street-view-container {
    flex: 2;
    padding: 20px;
    margin-top: 3%;
    align-items: center;
    /* padding-left: 20px; */
    border-radius: 8px;
    width: 38vw;
}

.offer-staffing-order-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.offer-staffing-order-name, .offer-staffing-order-id {
    font-weight: bold;
    color: #333;
}

.offer-staffing-order-address {
    font-size: 14px;
    color: #666;
}

.offer-staffing-order-total {
    font-weight: bold;
    color: #333;
    margin-top: 5px;
}

.offer-googlestreetview {
    flex-grow: 1; 
    height: 400px; 
    /* margin-top: 5px; */
    overflow: hidden; 
    border-radius: 8px; 
}

.offer-googlestreetview > div {
    border-radius: 8px;
    height: 100%; /* Full height of the card */
    width: 100%;
}



.offer-staffing-status-item {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
}

/* offer status profile selectors on click  */

.offer-status-profiles-outer-container {
    overflow: hidden;
    border-radius: 16px;
    background: #F9FAFB;
    padding: 16px;
    max-width: 95%;
    /* margin: 0 auto; */
    box-sizing: border-box;
  }
  
  .offer-status-profiles-container {
    display: flex;
    gap: 16px;
    padding: 8px 0;
    overflow-x: auto;
    scrollbar-width: thin;
    height: 100px;
  }
  
  .offer-status-profile-card {
    height: 80px;
    width: 100px;
    flex-shrink: 0;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  /* .offer-status-profile-circle {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid #E4E4E4;
    background: lightgray;
  } */

  .offer-status-profile-circle {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 1px solid #E4E4E4;
    background: lightgray;
    overflow: hidden; /* Ensures the image stays within the circle */
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer-status-profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the circle without distortion */
    border-radius: 50%; /* Ensures the image itself is also circular */
}

  
  .offer-status-active {
    border: 2px solid #CC457D;
    /* box-shadow: 0px 0px 8px 0px rgba(255, 62, 62, 0.6); */
    box-shadow: 0px 8px 24px 0px rgba(204, 69, 125, 0.24);
  }
  
  .offer-status-profile-name {
    margin-top: 8px;
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
  
  /* Scrollbar styling for horizontal scrolling */
  .offer-status-profiles-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .offer-status-profiles-container::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background: #F2F2F2;
  }
  
  .offer-status-profiles-container::-webkit-scrollbar-track {
    background: transparent;
  }