@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.dashboard, .main_layout_content {
    display: flex;
    min-height: 100vh; 
    /* background: #f4f4f4; */
    /* background: #f8f9fd; */
    background: linear-gradient(180deg, #D7E1FF 0%, #EEF1FA 80.5%, #FFF 100%);
    font-family: Inter;
  }
  .dashboard_content{
    width: 100%;
    background: white;
  }
  .main_layout_content_inner{
    width: 100%;
    background: linear-gradient(180deg, #D7E1FF 0%, #EEF1FA 80.5%, #FFF 100%);
  }
  
  .main-content {
    flex-grow: 1; 
    overflow-y: auto; 
    padding: 5px;
    height: calc(100vh - 10px); 
    position: relative;
  }
  
  .main-content::after {
    content: '';
    position: absolute; 
    top: 0;
    right: 0;
    width: 20px; 
    height: 100%;
    background: #f8f9fd;
  }
  
  .sidebar {
    flex-shrink: 0; 
    height: auto; 
  }
  
  .status-tabs {
    display: flex;
    padding: 16px;
    /* background: #F4F5F7; */
    /* background: #f8f9fd; */
    color: #db2777;
  }
    
  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    margin: 0 4px;
    background: white;
    border-radius: 25px;
    box-shadow: none; /* This will be set for active tab */
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .tab.active {
    /* background: #000000; */
    background: #db2777;
    color: white;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }
  
  .tab .count {
    font-size: 14px;
    font-weight: bold;
  }
  
  .tab .label {
    font-size: 12px;
  }
  
  /* Adjust the .tab:not(.active):hover selector to handle hover states for non-active tabs if needed */
  .tab:not(.active):hover {
    background: #e6e6e6;
  }
  
  /* Responsive adjustments as needed */
  @media (max-width: 600px) {
    .status-tabs {
      flex-direction: column;
    }
    .tab {
      width: 100%;
      margin: 4px 0;
    }
  }

  .new-orders-tab {
    background-color: #f8f9fd;
    padding: 20px;
  }
  
  .new-orders-header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #3e4784;
  }

  .orders-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .orders-container {
      flex-direction: column;
    }
  }

  .order-card {
    flex: 1;
    min-width: 300px; 
    max-width: 350px; 
  }


  .order-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    /* align-items: center; */
  }
  
  .time-circle {
    /* background-color: #dff0d8; */
    background-color: #fdf2f8 ; 
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  
  .time-left {
    font-size: 20px;
    font-weight: 600;
    color: #3e4784
  }
  
  .order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-name {
    font-size: 18px;
    color: #3e4784;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .order-id {
    font-size: 14px;
    color: #999;
  }
  
  .order-address {
    margin-top: 0px;
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
  }
  
  .order-total {
    font-size: 14px;
    color: #3e4784;
    margin-bottom: 0px;
  }
  
  .total-amount {
    font-weight: bold;
  }
  
  .order-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
    padding: 5px 0;
  }
  
  .restaurant-info {
    display: flex;
    justify-content: space-between;
  }
  
  .restaurant {
    padding: 10px;
    border-radius: 4px;
    background-color:#fdf2f8 ; 
    font-size: 14px;
    color: #3e4784;
    text-align: center;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .time {
    font-size: 12px;
  }
  
  .order-content {
    flex-grow: 1; 
    display: flex;
    flex-direction: column; 
  }
  
  .order-header,
  .order-total,
  .order-details,
  .restaurant-info {
    margin-bottom: 14px; 
  }

  .restaurant-info {
    display: flex;
    justify-content: start;
    gap: 20px; 
  }
  
  .restaurant {
    flex-grow: 1;
    padding: 10px;
    margin-top: 16px; 
    text-align: left; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  }
  

/* == in progress ========= */ 

  .in-progress-orders-tab {
    padding: 20px;
  }
  
  .in-progress-orders-header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #3e4784;
  }
  
  .in-progress-order-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start; 
  }

  .time-left-indicator {
    border-radius: 50%;
    width: 40px; 
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    color: #3e4784
  }

  /* ==== completed orders tab ===== */

  .completed-orders-tab {
    padding: 20px;
  }
  
  .completed-orders-header {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #3e4784;
  }
  
  .completed-orders-container {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .completed-order-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 20px;
    /* flex-basis: calc(50% - 20px); */
  }
  
  .order-rating {
    font-size: 16px;
    color: #3e4784;
    margin-bottom: 10px;
  }
  
  .order-review {
    font-size: 14px;
    color: #666;
  }

  .recommended-link {
    text-decoration: underline;
    color: #3e4784 !important;
  }

  .circle-number {
    width: 24px; /* Adjust based on your preferred size */
    height: 24px; /* Same as width for a perfect circle */
    border-radius: 50%; /* Perfect circle */
    /* background-color: #FFFFFF;  */
    background-color: #F5F5F5; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    color: #404880; 
    font-size: 12px;
    font-weight: 500;

    /* background-color: #404880; Active background */
    /* color: #FFF; Active text color */
}

.circle-number.active {
    background-color: #404880; /* Active background */
    color: #FFF; /* Active text color */
}


.circle-number-open {
  width: 24px; 
  height: 24px; 
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: #4CAF50; /* Green for open */
  color: #FFF; 
}

.circle-number-accepted {
  width: 24px; 
  height: 24px; 
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: #404880; /* Blue for accepted */
  color: #FFF; 
}

.circle-number-rejected {
  width: 24px; 
  height: 24px; 
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  font-weight: 500;
  background-color: #F44336; /* Red for rejected */
  color: #FFF; 
}

