.officer_dashboard{
    background: linear-gradient(180deg, #D9E2FC 0%, #FFF 100%), #FAFAFA;
}
.officer_dashboard .create-new-job-button{
    display: flex;
    align-items: center;
    gap: 8px;
}
.officer_dashboard .main-content{
    padding: 16px;
    height: auto;
}
.officer_dashboard .main-content::after{
    display: none;
}
.officer_dashboard .status-tabs-wraper{
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 24px;
}
.officer_dashboard .status-tabs{
    padding: 0;
    gap: 16px;
}
.officer_dashboard .status-tabs .tab{
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background: transparent;
    border-radius: 0;
    flex-direction: row;
    box-shadow: none;
    border-bottom: 1px solid transparent;
}
.officer_dashboard .status-tabs .tab.active{
    border-bottom: 1px solid #404880;
}
.officer_dashboard .status-tabs .tab .label{
    color: #878787;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}
.officer_dashboard .status-tabs .tab.active .label{
    color: #404880;
}
.officer_dashboard .status-tabs .tab .count{
    color: #404880;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 50%;
    background: white;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.officer_dashboard .status-tabs .tab.active .count{
    background: #404880;
    color: #FFF;
}
.officer_dashboard .view_box{
    position: relative;
    padding: 0;
}
.officer_dashboard .view_box .box-view{
    display: flex;
    align-items: center;
    justify-content: center;
}
.officer_dashboard .staff-new-orders-tab{
    padding: 0;
}
.officer_dashboard .staff-new-orders-tab .gridView{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}
.officer_dashboard .staff-new-orders-tab .gridView .staff-order-card{
    margin: 0;
}
.officer_dashboard .staff-new-orders-tab .listView .staff-order-card{
    flex-direction: row;
    gap: 16px;
}
.officer_dashboard .staff-new-orders-tab .listView .staff-order-card .staff-order-name{
    margin: 0;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card{
    max-width: 100%;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-googlestreetview{
    position: relative;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-googlestreetview .staff-order-id{
    position: absolute;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    padding: 8px;
    border-radius: 9px;
    background: rgba(0, 0, 0, 0.56);
    backdrop-filter: blur(2.5px);
    bottom: 8px;
    right: 8px;
    width: auto;
    height: auto;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-order-header{
    margin-bottom: 8px;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-order-header .staff-order-name{
    font-size: 14px;
    font-weight: 700;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-order-address{
    margin: 0 0 8px;
    color: #878787;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
}
.officer_dashboard .staff-new-orders-tab .listView .staff-order-card .staff-googlestreetview{
    width: 400px;
    height: 210px;
}
.officer_dashboard .staff-new-orders-tab .listView .staff-order-card .staff-order-content-wraper{
    width: calc(100% - 400px);   
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-order-content,
.officer_dashboard .staff-new-orders-tab .staff-order-card .decision-time-note{
    margin: 0;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .decision-time-note{
    border-radius: 8px;
    background: #F5F5F5;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .terms_conditions{
    color: #4F4F4F;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .terms_conditions input{
    border-color: #BDBDBD;
    width: 20px;
    height: 20px;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .terms_conditions a{
    color: #2F80ED;    
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info{
    margin-bottom: 0;
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    gap: 8px;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info .staff-restaurant{
    border-radius: 8px;
    box-shadow: 0px 8px 32px 0px rgba(69, 123, 60, 0.24);
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 16px;
    margin: 0;
    max-width: 150px;
}
.officer_dashboard .staff-new-orders-tab .gridView .staff-order-card .staff-restaurant-info .staff-restaurant{
    max-width: 100%;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info .staff-restaurant .staff-label{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info .staff-restaurant .staff-label svg{
    width: 12px;
    height: 12px;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info .accept{
    background: #457B3C;
}
.officer_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info .reject{
    background: #B73831;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .staff-accepte-details{
    margin-top: 10px;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .staff-accepte-details .title{
    color: #878787;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .staff-accepte-details .staff-order-total{
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 0 4px;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .staff-accepte-details .shift-checkins-container{
    padding: 0;
    margin: 16px 0;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-tabs .shift-checkins-tab{
    border-radius: 8px 8px 0px 0px;
    border: 1px 1px 0 solid transparent;
    background: transparent;
    color: #616161;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 400;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-tabs .shift-checkins-tab.shift-checkins-tab-active{
    border-color: #E0E0E0;
    background: #EBECF5;
    color: #000;    
    font-weight: 600;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-details-container{
    padding: 8px;
    border-radius: 0px 8px 8px 8px;
    border: 1px solid #E4E4E4;
    background: #FFF;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-details-container .shift-checkins-icon-text{
    display: flex;
    align-items: center;
    gap: 8px;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-details-container .shift-checkins-icon-text svg{
    margin: 0;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-details-container .shift-checkins-icon-text svg path{
    fill: #CB3A76;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-details-container .shift-checkins-icon-text span{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.accepeted_dashboard .staff-new-orders-tab .gridView .staff-order-card .shift-checkins-details-container .shift-checkins-meeting-details{
    display: none;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .shift-checkins-details-container .shift-checkins-status{
    margin: 0;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info .staff-restaurant{
    border-radius: 8px;
    background: #404880;
    border: 1px solid #404880;
    max-width: 200px;
    box-shadow: none;
}
.accepeted_dashboard .staff-new-orders-tab .staff-order-card .staff-restaurant-info .check-out{
    color: #404880;
    background: white;
}

@media (max-width: 1024px) {
    .officer_dashboard .staff-new-orders-tab .listView .staff-order-card{
        flex-direction: column;
        gap: 0;
    }
    .officer_dashboard .staff-new-orders-tab .listView .staff-order-card .staff-order-name{
        margin-top: 10;
    }
    .officer_dashboard .staff-new-orders-tab .listView .staff-order-card .staff-googlestreetview{
        width: 100%;
    }
    .officer_dashboard .staff-new-orders-tab .listView .staff-order-card .staff-order-content-wraper{
        width: 100%;   
    }
    .officer_dashboard .staff-new-orders-tab .gridView{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 576px) {
    .officer_dashboard .staff-new-orders-tab .gridView{
        grid-template-columns: repeat(1, 1fr);
    }
}