@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


 .staff-assignments-cards-container {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); */
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 20px;
    padding: 20px;
    font-family: Inter;
  }
  
  .staffing-new-assignments-body {
    height: calc(100vh - 94px);
    width: 100%;
    overflow: auto; 
    background: linear-gradient(180deg, #D7E1FF 0%, #EEF1FA 80.5%, #FFF 100%);
    box-sizing: border-box; 
  }
  .staffing-new-assignments_inner{
    padding: 20px;    
  }
  .staffing-new-assignments_inner .gridbox_view{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-bottom: 24px;
  }
  .staffing-new-assignments_inner .gridbox_view .card_view{
    border-radius: 8px;
    padding: 24px;
    background: white;

  }
  .staffing-new-assignments_inner .gridbox_view h4{
    font-size: 16px;
    font-weight: 400;
    color: #545454;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .staffing-new-assignments_inner .gridbox_view h2{
    font-size: 24px;
    font-weight: 600;
    color: black;
    margin: 0;
  }
  .staffing-new-assignments_inner .tabs_content .rs-nav-subtle{
    display: flex;
    gap: 16px;
    border-color: #878787;
  }
  .staffing-new-assignments_inner .tabs_content .rs-nav-item{
    padding: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #878787;
  }
  .staffing-new-assignments_inner .tabs_content .rs-nav-bar{
    border-top-color: #E4E4E4;
    border-top-width: 1px !important;
  }
  .staffing-new-assignments_inner .tabs_content .rs-nav-item-active{
    font-weight: 600;
    color: #404880;
  }
  .staffing-new-assignments_inner .tabs_content .rs-nav-item-active::before{
    background-color: #404880 !important;
    height: 1px !important;
  }
  .view_box{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #E4E4E4;
    padding: 2px;
    border-radius: 8px;
  }
  .view_box .box-view{
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    background: white;
    color: #404880;
  }
  .view_box .box-view.active{
    background: #404880;
    color: white;
  }
  .view_box .box-view svg path{
    stroke: #404880;
  }
  .view_box .box-view.active svg path{
    stroke: white;
  }

  .page_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    background: #fff;
  }
  .page_title .left_side h2{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 2px;
    color: black;
  }
  .page_title .left_side p{
    font-size: 16px;
    font-weight: 400;
    color: #878787;
    margin: 0;
  }
  
  .page_title .right_side .create__btn{
    background: #404880;
    padding: 12px 16px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  .offer-staffing-street-view-container {
    /* background: #fff;  */
    border-radius: 8px; 
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */
    overflow: hidden; 
    padding: 20px; 
    box-sizing: border-box; 
  }
  
  .staff-order-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .staff-order-name {
    font-size: 1.2em;
  }
  
  .staff-order-id {
    font-size: 0.9em;
    color: #888; 
  }
  
  .staff-order-address, .staff-order-total {
    margin: 5px 0;
  }
  
  hr {
    border: none;
    border-top: 1px solid #eee; 
    margin: 10px 0;
  }
  
  /* new */ 
  .job-details {
    display: flex;
    align-items: center;
    gap: 4.5px; 
  }
  
  .staff-required-circle {
    width: 18px; 
    height: 18px; 
    border-radius: 50%; 
    /* background-color: #db2777;  */
    background-color: #3e4784; 
    color: white; 
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 1em;  */
    /* font-weight: bold;  */
  }


  .create-new-job-button {
    border-radius: 8px;
    background: #404880;
    padding: 12px 16px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  

  