.shift-calendar-modal {
    position: absolute;
    padding: 1rem;
    background-color: #eaecf4;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
    width: 92vw; 
    height: 92vh; 
    display: flex;
    flex-direction: column;
    overflow: hidden!important; /* Prevent the entire modal from scrolling */
}

.shift-calendar-modal-overlay {
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(126, 121, 121, 0.4);
}

.calendar-agenda-modal-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden; 
}

.calendar-agenda-modal-header {
    flex-shrink: 0;
}

.calendar-agenda-modal-body {
    flex-grow: 1;
    overflow-y: auto; 
    overflow: hidden;
}

.calendar-agenda-modal-footer {
    flex-shrink: 0;
    text-align: center;
    margin-top: 20px;
}

.add-to-calendar-btn {
    padding: 10px 20px;
    background-color: #3D4784;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-to-calendar-btn:hover {
    background-color: #2c3570;
}
