.staffing-notifications-dropdown {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 320px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .staffing-notifications-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .staffing-notifications-header h2 {
    font-size: 18px;
    color: #333333;
    margin: 0;
  }
  
  .staffing-notifications-new {
    /* background-color: #1da1f2; */
    color: #ffffff;
    border: none;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .staffing-notifications-list {
    max-height: 300px;
    overflow-y: auto;
  }
  
  .staffing-notifications-empty {
    padding: 16px;
    font-size: 14px;
    color: #888888;
    text-align: center;
  }
  
  .staffing-notifications-item {
    list-style: none;
    padding: 12px 16px;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .staffing-notifications-item.unread h3 {
    /* color: #1da1f2; */
  }
  
  .staffing-notifications-item.read h3 {
    color: #333333;
  }
  
  .staffing-notifications-link {
    display: flex;
    text-decoration: none;
    color: inherit;
    width: 100%;
  }
  
  .staffing-notifications-content {
    display: flex;
    flex-direction: column;
  }
  
  .staffing-notifications-content h3 {
    font-size: 16px;
    margin: 0;
    font-weight: normal;
  }
  
  .staffing-notifications-time {
    font-size: 12px;
    color: #888888;
    margin-top: 4px;
  }
  
  .staffing-notifications-footer {
    padding: 12px 16px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
  }
  
  .staffing-notifications-mark-read {
    background-color: transparent;
    border: none;
    /* color: #1da1f2; */
    color: #CC457D;
    cursor: pointer;
    font-size: 14px;
  }

  /* Ensure the dropdown appears directly below the notification icon */
.notification {
    position: relative; /* Set the notification icon as the relative container */
  }
  
  .staffing-notifications-dropdown {
    position: absolute; /* Position dropdown absolutely within the notification icon container */
    top: 100%; /* Aligns it right below the icon */
    right: 0; /* Aligns it to the right edge of the icon */
    margin-top: 8px; /* Adds some spacing from the icon */
    z-index: 10; /* Ensures it appears above other elements */
  }
  
  .notification-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background-color: #CC457D; 
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 6px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  