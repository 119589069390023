

.staff-new-orders-tab {
  /* background-color: #f8f9fd; */
  padding: 10px;
}

.staff-order-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Changed to column to stack content */
    align-items: stretch; /* Align items */
    margin-bottom: 20px;
    max-width: 98%;
    max-height: 550px;
    padding: 15px;
    
}

.staff-order-id {
    color: #999;
    font-size: 14px;
}

.staff-order-name {
    color: #3e4784;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 10px;
}

.staff-order-address,
.staff-order-total,
.staff-order-header,
.staff-restaurant-info {
    color: #666; /* Default color, can be overridden */
    font-size: 14px; /* Default font-size, can be overridden */
    margin-bottom: 3px;
}



.staff-new-orders-header {
    color: #3e4784;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.staff-order-content {
    margin-bottom: 10px;
}

.staff-googlestreetview {
    flex-grow: 1; /* Allow the map section to grow */
    height: 450px; /* Increase height for better map visibility */
    /* margin-top: 5px; */
    overflow: hidden; /* Adjust overflow as needed */
    border-radius: 8px; /* Optional: Adds rounded corners to the map */
}

.staff-googlestreetview > div {
    border-radius: 8px;
    height: 100%; /* Full height of the card */
    width: 100%;
}


.staff-restaurant-info {
    display: flex;
    justify-content: start; /* Changed to start for better spacing */
    margin-top: 8px;
  }
  
  .staff-restaurant {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    margin: 0 8px; 
    padding: 10px;
    text-align: center;
    cursor: pointer; 
    font-weight: 600;
  }
  
  
  .staff-restaurant.accept {
    background-color: #e8f5e9; 
    color: #2e7d32; 
  }
  
  .staff-restaurant.accept .staff-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .staff-restaurant.reject {
    background-color: #ffebee; 
    color: #c62828; 
  }
  
  .staff-restaurant.reject .staff-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  

  .CheckIcon, .ClearIcon {
    margin-right: 5px;
  }
  

  

  .decision-time-note {
    color: #666; /* Subdued color for elegance */
    font-size: 14px; /* Appropriate font size for readability */
    /* font-style: italic; 
    text-align: center;  */
    margin-top: 14px; /* Adequate spacing from the buttons */
  }
  