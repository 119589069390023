/*
.job-status-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px; 
    background-color: #f7f8fa; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: #2b3945; 
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1); 
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
}

.job-status-footer-kpis {
    display: flex;
    align-items: center;
}

.job-status-footer-kpi {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    color: #3d4784;
}

.job-status-footer-count {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;
}

.job-status-footer-label {
    font-size: 12px;
    color: #bababa;
}

.job-status-footer-button {
    background-color: transparent; 
    color: #3d4784; 
    border: none;
    border-radius: 4px;
    padding: 0; 
    cursor: pointer;
    font-size: 14px;
    font-weight: normal; 
}

.job-status-footer-button:hover {
    text-decoration: none; 
    color: #db2677; 
}

.shift-plan-modal {
    background-color: #fff;
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 8px;
    outline: none;
}

.shift-plan-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-modal-button {
    background-color: #db2677;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
}

.close-modal-button:hover {
    background-color: #c51f69;
}
*/ 


.job-status-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px; 
    /* background-color: #24293d;  */
    background-color: #2f313b; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: #d1d4e3; /* Light, soft text color */
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    z-index: 99;
}

.job-status-footer-kpis {
    display: flex;
    align-items: center;
}

.job-status-footer-kpi {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    color: #c4c8df; /* Softer text for KPI labels */
}

.job-status-footer-count {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;
    color: #f0f1fa; /* Bright text color for counts */
}

.job-status-footer-label {
    font-size: 12px;
    color: #a6a8bd; /* Softer, subtle color for labels */
}

.job-status-footer-button {
    background-color: transparent; 
    color: #82c7ff; /* Muted blue for buttons */
    border: 1px solid #82c7ff;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal; 
}

.job-status-footer-button:hover {
    background-color: #5aa4e0; /* Slightly darker blue on hover */
    color: #ffffff;
    border-color: #5aa4e0;
}

.shift-plan-modal {
    background-color: #2e3550; /* Softer, more natural dark shade */
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 8px;
    outline: none;
    color: #f8f9fa; /* Light text color */
}

.shift-plan-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Softer dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-modal-button {
    background-color: #82c7ff;
    color: #24293d; /* Matching the darker footer shade */
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-top: 20px;
}

.close-modal-button:hover {
    background-color: #5aa4e0;
}
